import { useQuery } from "@apollo/client";
import React from "react";
import { SEARCH_LOCATION } from "../../gql";
import EventMetrics from "./EventMetrics";
import GigMetrics from "./GigMetrics";
import UserMetrics from "./UserMetrics";

function Metrics({ start, end }) {
  const { data } = useQuery(SEARCH_LOCATION);
  const { location } = data || {};

  const cleanedLocation = location
    ? {
        latitude: location.latitude,
        longitude: location.longitude,
      }
    : null;

  return (
    <div style={{ paddingTop: "24px" }}>
      <UserMetrics location={cleanedLocation} start={start} end={end} />
      <EventMetrics location={cleanedLocation} start={start} end={end} />
      <GigMetrics location={cleanedLocation} start={start} end={end} />
    </div>
  );
}

export default Metrics;
