import { useMutation } from "@apollo/client";
import { H1 } from "@blueprintjs/core";
import React from "react";
import { SEND_MARKETING_NOTIFICATION } from "./gql";
const Push = () => {
  const [title, setTitle] = React.useState();
  const [message, setMessage] = React.useState();
  const [rawData, setData] = React.useState();

  const [sendPush, { loading }] = useMutation(SEND_MARKETING_NOTIFICATION, {
    onCompleted: () => {
      setTitle("");
      setMessage("");
      setData("");
      window.alert("Push sent!");
    },
    onError: (error) => {
      window.alert(error.message);
    },
  });

  const handleSubmit = async () => {
    if (!title || !message) {
      window.alert("Please fill out all fields");
      return;
    }
    let data;
    if (rawData) {
      try {
        data = JSON.parse(rawData);
      } catch (error) {
        window.alert("Invalid JSON");
        return;
      }
    } else {
      data = {};
    }

    await sendPush({
      variables: {
        title,
        message,
        data,
        audience: "DJ_NOT_PRO",
      },
    });
  };

  return (
    <div className="flex flex-col w-full p-6">
      <div className="flex flex-row justify-between mb-6">
        <div className="flex flex-row items-end">
          <H1 className="mb-0 mr-4">Send push</H1>
        </div>
      </div>

      <p>Currently sends to all DJs that are not paying members</p>

      <form>
        <div className="flex flex-col mb-6 mt-12">
          <label className="mb-2">Title</label>
          <input
            className="bp3-input"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="flex flex-col mb-6">
          <label className="mb-2">Message</label>
          <input
            className="bp3-input"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="flex flex-col mb-6">
          <label className="mb-2">Data (JSON)</label>
          <textarea
            className="bp3-input"
            value={rawData}
            onChange={(e) => setData(e.target.value)}
          />
        </div>
        <div className="flex flex-row justify-end">
          <button
            disabled={loading}
            className="bp3-button bp3-intent-primary"
            type="button"
            onClick={handleSubmit}
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default Push;
