import {
  Button,
  Card,
  H1,
  H2,
  H5,
  Icon,
  Intent,
  MenuItem,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import moment from "moment";
import React from "react";
import Chat from "../Components/Chat";
import InfoStat from "../Components/InfoStat";
import { statusIntentMap } from "../Events";
import { getAuthToken } from "../utils/auth";
import { CHANGE_GIG_STATE, GIG_DETAIL } from "./gql";

import { useMutation } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import Avatar from "../Components/Avatar";
import CancelationPolicy from "../Components/CancelationPolicy";

const GigDetail = ({ match, history }) => {
  const { id } = match.params;
  const navigate = (route) => history.push(route);

  const [mutate, { loading: loadingNewState }] = useMutation(CHANGE_GIG_STATE, {
    refetchQueries: [{ query: GIG_DETAIL, variables: { id } }],
  });

  const selectNewState = (newState) => {
    // first confirm,
    const confirmed = window.confirm("Change the gigstate to: " + newState);

    let data = {};
    if (newState === "CONFIRMED") {
      data = {
        paymentID: "direct",
        paymentCurrency: 0,
        paymentAmount: 0,
        paymentProvider: 3,
      };
    }

    if (confirmed) {
      mutate({
        variables: {
          gigId: id,
          newState,
          data,
        },
      });
    }
  };

  return (
    <>
      <Query query={GIG_DETAIL} variables={{ id }}>
        {({ loading, data }) => {
          const { gig } = data || {};
          if (loading) {
            return (
              <div className="flex justify-center items-center h-full">
                <Spinner className="self-center m-auto "></Spinner>
              </div>
            );
          }

          if (!gig) {
            return (
              <div className="flex justify-center items-center h-full">
                Gig not found
              </div>
            );
          }

          const { event = {}, organizer, dj, status } = gig;

          const loadingClass = loading ? "bp3-skeleton" : "";

          return (
            <div className="flex flex-col w-full p-6">
              <div
                className="flex flex-row justify-between border-b mb-6 align-center"
                style={{
                  borderColor: "rgba(255, 255, 255, 0.15)",
                }}
              >
                <div className="flex items-end items-end ">
                  <H1 className={loadingClass}>{`Gig`}</H1>
                  <H5 className={"ml-4 mb-3 " + loadingClass} loading={loading}>
                    {event.name}
                  </H5>
                </div>

                <div className={"mt-2 flex " + loadingClass}>
                  {loadingNewState ? (
                    <span>
                      <Spinner
                        size={Spinner.SIZE_SMALL}
                        className="bp3-dark mr-6"
                      />
                    </span>
                  ) : (
                    <Select
                      filterable={false}
                      items={[
                        "INITIAL",
                        "REQUESTED",
                        "ACCEPTED",
                        "DECLINED",
                        "CANCELLED",
                        "EVENT_CANCELLED",
                        "LOST",
                        "CONFIRMED",
                        "FINISHED",
                      ]}
                      itemRenderer={(s, { handleClick }) => {
                        return <MenuItem text={s} onClick={handleClick} />;
                      }}
                      noResults={<MenuItem disabled={true} />}
                      onItemSelect={selectNewState}
                    >
                      {/* children become the popover target; render value here */}
                      <Button
                        text={status}
                        rightIcon="double-caret-vertical"
                        className="mr-6"
                      />
                    </Select>
                  )}

                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://cueup.io/event/${event.id}/${event.hash}/offers`}
                  >
                    <Button rightIcon="arrow-right">View on website</Button>
                  </a>
                </div>
              </div>

              <div className="flex flex-row justify-between align-left flex-wrap">
                {gig.offer && <OfferCard gig={gig} />}

                <EventCard
                  event={event}
                  organizer={organizer}
                  onClick={(_) => navigate("/event/" + event.id)}
                />

                {dj && (
                  <DJCard dj={dj} onClick={(_) => navigate("/user/" + dj.id)} />
                )}

                <CustomCard title={<H2>CHAT</H2>}>
                  <Chat
                    chatId={id}
                    sender={dj || {}}
                    receiver={organizer || {}}
                    token={getAuthToken()}
                  ></Chat>
                </CustomCard>
              </div>
            </div>
          );
        }}
      </Query>
    </>
  );
};

const CustomCard = ({ onClick, title, children }) => {
  return (
    <button className="hover:shadow-lg trans mb-8" onClick={onClick}>
      <Card
        style={{ width: "590px", height: "300px" }}
        className="text-left flex-col flex"
      >
        <div className="flex flex-row justify-between border-b border-gray-700">
          <div className="mr-4 ">{title}</div>
          <Icon icon="arrow-right"></Icon>
        </div>
        <div className="mt-4 flex-col flex flex-1 justify-between overflow-scroll">
          {children}
        </div>
      </Card>
    </button>
  );
};

const CustomStat = ({ ...props }) => {
  return <InfoStat className="mb-4 w-40" {...props}></InfoStat>;
};

const OfferCard = ({ gig }) => {
  const {
    status,
    statusHumanized,
    offer: outerOffer,
    directBooking,
    reason,
    affiliateLinkId,
  } = gig;

  const {
    cancelationPolicy = {},
    offer = {},
    serviceFee = {},
    djFee = {},
    totalPayment = {},
    totalPayout = {},
    canBePaid,
    daysUntilPaymentPossible,
    bookingConfirmedDate,
  } = outerOffer;

  return (
    <CustomCard
      title={
        <div className="items-center flex flex-row mb-2">
          <H2 className="mb-0">OFFER</H2>
          <Tag
            large
            minimal
            round
            intent={statusIntentMap[status]}
            className="text-center ml-4"
          >
            {status}
          </Tag>
          {(canBePaid || daysUntilPaymentPossible > 0) && (
            <Tag
              large
              minimal
              round
              intent={canBePaid ? Intent.SUCCESS : Intent.WARNING}
              className="text-center ml-2"
            >
              {canBePaid
                ? "Payable"
                : "Can be paid in " + daysUntilPaymentPossible + " days"}
            </Tag>
          )}
          {directBooking && (
            <Tag
              large
              minimal
              round
              intent={Intent.PRIMARY}
              className="text-center ml-4"
            >
              Direct booking
            </Tag>
          )}
        </div>
      }
    >
      <div className="flex flex-row flex-wrap">
        <CustomStat unit="Offer" val={offer.formatted}></CustomStat>

        <CustomStat unit="Service Fee" val={serviceFee.formatted}></CustomStat>

        <CustomStat unit="DJ fee" val={djFee.formatted}></CustomStat>

        <CustomStat
          unit="Total payment"
          val={totalPayment.formatted}
        ></CustomStat>

        <CustomStat
          unit="Total payout"
          val={totalPayout.formatted}
        ></CustomStat>
      </div>

      {bookingConfirmedDate && (
        <p className="mt-4 ">
          <b>Booking confirmed date:</b>{" "}
          {new Date(bookingConfirmedDate).toLocaleString()}
        </p>
      )}

      {reason ? (
        <p className="mt-4 ">
          <b>Reason by dj:</b> "{reason}"
        </p>
      ) : (
        <p className="mt-4 ">{statusHumanized}</p>
      )}

      {cancelationPolicy.days && (
        <CancelationPolicy cancelationPolicy={cancelationPolicy} />
      )}

      {affiliateLinkId && <p className="mt-4 ">AFFILIATE: {affiliateLinkId}</p>}
    </CustomCard>
  );
};

const EventCard = ({
  organizer,
  event: {
    name,
    contactName,
    contactEmail,
    contactPhone,
    guestsCount,
    review,
    start = {},
    end = {},
    duration = {},
    location = {},
    hash,
    genres = [],
    createdAt = {},
    description,
    rider = {},
    status,
  },
  ...props
}) => (
  <CustomCard
    title={
      <div className="items-center flex flex-row mb-2 ">
        <H2 className="mb-0">EVENT</H2>
        <p className="text-gray-600 ml-2">{start.formattedDate}</p>
        <Tag
          large
          minimal
          round
          intent={statusIntentMap[status]}
          className="text-center ml-4"
        >
          {status}
        </Tag>
      </div>
    }
    {...props}
  >
    <div className="flex flex-row flex-wrap">
      <CustomStat unit="Start" val={start.formattedTime}></CustomStat>

      <CustomStat unit="End" val={end.formattedTime}></CustomStat>

      <CustomStat unit="Duration" val={duration.formatted}></CustomStat>

      <CustomStat unit="Location" val={location.name}></CustomStat>
      <CustomStat
        unit="Created"
        val={moment(createdAt.UTC).fromNow()}
      ></CustomStat>
    </div>

    {organizer && (
      <div className="flex flex-row items-center">
        <Avatar path={organizer.picture.path}></Avatar>
        <div className="flex-col flex justify-center">
          <div className="mb-1  font-bold text-xs">ORGANIZER</div>
          <div className="text-gray-500 ">
            {organizer.userMetadata.firstName} {organizer.userMetadata.lastName}
          </div>
          <div className="text-gray-600 ">{organizer.email}</div>
        </div>
      </div>
    )}
  </CustomCard>
);

const DJCard = ({
  dj: {
    email,
    userMetadata: { firstName, lastName },
    picture,
  },
  ...props
}) => (
  <CustomCard
    title={
      <div className="items-center flex flex-row mb-2 ">
        <H2 className="mb-0">DJ</H2>
      </div>
    }
    {...props}
  >
    <div className="flex flex-row items-center">
      <Avatar path={picture.path}></Avatar>
      <div className="flex-col flex justify-center">
        <div className="text-gray-500 ">
          {firstName} {lastName}
        </div>
        <div className="text-gray-600 ">{email}</div>
      </div>
    </div>
  </CustomCard>
);

export default GigDetail;
