import React from "react";
import { USER_GIGS } from "./gql";
import GigsTable from "../Components/GigsTable";

const Gigs = ({ id }) => {
	return (
		<GigsTable
			id={id}
			query={USER_GIGS}
			extractor={data => {
				const { user = {} } = data;
				const { gigs = {} } = user;
				return gigs;
			}}
		/>
	);
};

export default Gigs;
