const onLoggedIn = token => {
	window.localStorage.setItem("auth_token", token);

	return token;
};

const isLoggedIn = () => {
	return window.localStorage.getItem("auth_token");
};

const onLogout = () => {
	return window.localStorage.removeItem("auth_token");
};

const getAuthToken = () => {
	return window.localStorage.getItem("auth_token");
};

export { onLoggedIn, isLoggedIn, onLogout, getAuthToken };
