import gql from "graphql-tag";

const USER_DETAIL = gql`
  query UserDetail($id: ID!) {
    user(id: $id) {
      id
      email
      permalink
      genres

      picture {
        id
        path
      }
      playingLocation {
        name
        radius
        longitude
        latitude
      }
      appMetadata {
        roles
        emailVerified
        experience
        createdAt
        rating
        certified
        approved
        internalRating
        profileStatus
        identityVerified
        hasInstalledApp
        lastActiveAt
        useLegacyPayout
        stripeId
        stripeCustomerId
        banned
      }
      userMetadata {
        firstName
        lastName
        bio
        birthday
        address
        city
        postalCode
        phone
      }
      payoutMethods {
        id
        payoutType
        paymentProvider
      }
      userSettings {
        id
        cancelationPolicy {
          days
          percentage
        }
        standby
        currency
      }
      edits {
        id
        status
        message
        fieldName
        value
        createdAt
      }
    }
  }
`;

const USER_GIGS = gql`
  query UserDetail($id: ID!, $pagination: Pagination!) {
    user(id: $id) {
      id
      gigs(pagination: $pagination) {
        pageInfo {
          page
          prevPage
          nextPage
          totalPages
          totalDocs
        }
        edges {
          id
          status
          statusHumanized
          directBooking
          seenByDJ
          opportunity
          seenByOrganizer
          chatInitiated
          event {
            id
            name
            start {
              UTC
            }
          }
          offer {
            totalPayment {
              amount
              formatted
            }
          }
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $standby: Boolean
    $certified: Boolean
    $approved: Boolean
  ) {
    updateUser(
      id: $id
      standby: $standby
      certified: $certified
      approved: $approved
    ) {
      id
      appMetadata {
        certified
        approved
        identityVerified
      }
      userSettings {
        id
        standby
      }
    }
  }
`;

const GENERATE_GIGS_FOR_USER = gql`
  mutation GenerateGigsForUSer($id: ID!) {
    generateGigsForUser(userId: $id)
  }
`;

const GET_MEDIA = gql`
  query GetMedia($id: ID!) {
    getMedia(id: $id) {
      id
      path
    }
  }
`;

const REVIEW_EDITS = gql`
  mutation ReviewEdits(
    $userId: ID!
    $internalRating: Int
    $reviews: [ReviewEdit!]
  ) {
    reviewEdits(
      userId: $userId
      reviews: $reviews
      internalRating: $internalRating
    ) {
      id
      picture {
        id
        path
      }
      userMetadata {
        bio
      }
      appMetadata {
        profileStatus
        approved
      }
      edits {
        id
        status
        message
        fieldName
        value
        createdAt
      }
    }
  }
`;

const BAN_USER = gql`
  mutation BanUser($id: ID!, $toggle: Boolean!) {
    banUser(id: $id, toggle: $toggle)
  }
`;

export {
  GET_MEDIA,
  USER_DETAIL,
  USER_GIGS,
  UPDATE_USER,
  GENERATE_GIGS_FOR_USER,
  REVIEW_EDITS,
  BAN_USER,
};
