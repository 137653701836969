import { useQuery } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import {
  Checkbox,
  H1,
  H6,
  HTMLTable,
  Intent,
  Tag,
  Text,
} from "@blueprintjs/core";
import moment from "moment";
import React, { useContext, useState } from "react";
import { AppContext } from "../App";
import Avatar from "../Components/Avatar";
import LoadingTable from "../Components/Table/LoadingTable";
import Pagination from "../Components/Table/Pagination";
import Td from "../Components/Table/Td";
import usePagination from "../Components/Table/usePagination";
import { EDIT_STATUS } from "../constants";
import { SEARCH_LOCATION } from "../gql";
import { useDebounce } from "../utils/useDebounce";
import { ALL_USERS } from "./gql";

const LIMIT = 9;

const Users = ({ history, isDj }) => {
  const { approvalMode, setAppState } = useContext(AppContext);
  const { page, setPage } = usePagination({ id: "users" });
  const [search, setSearch] = useState();
  const debouncedSearch = useDebounce(search, 500);

  const setApprovalMode = () => setAppState({ approvalMode: !approvalMode });

  const navigate = (route) => history.push(route);
  const { data } = useQuery(SEARCH_LOCATION);
  const { location } = data || {};

  const cleanedLocation = location
    ? {
        latitude: location.latitude,
        longitude: location.longitude,
      }
    : null;

  return (
    <div className="flex flex-col w-full p-6">
      <div className="flex flex-row justify-between mb-6">
        <div className="flex flex-row items-end">
          <H1 className="mb-0 mr-4">Users</H1>
          {location && (
            <p className="mb-1">
              @
              <Tag minimal round>
                {location.latitude.toFixed(2)}
              </Tag>
              <Tag minimal round>
                {location.longitude.toFixed(2)}
              </Tag>
            </p>
          )}
        </div>

        <div className="flex flex-row justify-end w-full mr-6 mt-3">
          <Checkbox
            checked={approvalMode}
            label="Approval Mode"
            onChange={() => setApprovalMode()}
          />
        </div>

        <div className="bp3-input-group bp3-large">
          <span className="bp3-icon bp3-icon-search"></span>
          <input
            className="bp3-input bp3-large"
            type="search"
            placeholder="Search"
            dir="auto"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <UsersTable
        approvalMode={approvalMode}
        location={cleanedLocation}
        page={page}
        search={debouncedSearch}
        setPage={setPage}
        navigate={navigate}
        isDj={isDj}
      />
    </div>
  );
};

const UsersTable = ({
  page,
  search,
  location,
  approvalMode,
  setPage,
  navigate,
  isDj,
}) => {
  const filter = {};
  const pagination = {
    page,
    limit: LIMIT,
  };
  if (location && location.latitude) {
    filter.location = location;
  }
  if (search) {
    filter.search = search;
  }
  if (isDj !== undefined) {
    filter.isDj = isDj;
  }

  if (approvalMode) {
    filter.profileStatus = EDIT_STATUS.PENDING;
    pagination.orderBy = "UPDATED_AT_ASCENDING";
  }

  return (
    <Query
      query={ALL_USERS}
      variables={{
        pagination,
        ...filter,
      }}
    >
      {({ loading, data, error }) => {
        const { users = {} } = data || {};
        const { edges = [], pageInfo } = users;

        if (loading) {
          return (
            <LoadingTable columns={6} rows={LIMIT} className="mx-auto w-full" />
          );
        }

        return (
          <>
            <HTMLTable interactive className="mx-auto w-full">
              <tbody>{edges.map((u) => UserRow(u, navigate, isDj))}</tbody>
            </HTMLTable>
            <Pagination {...pageInfo} setPage={setPage} />
          </>
        );
      }}
    </Query>
  );
};

const UserRow = (
  {
    id,
    picture,
    email,
    payoutMethods = [],
    userMetadata: { firstName, lastName },
    appMetadata: { emailVerified, profileStatus, createdAt },
    userSettings: { standby },
    playingLocation,
  },
  navigate,
  isDj
) => {
  return (
    <tr key={id} onClick={(_) => navigate("/user/" + id)}>
      <Td>
        <Avatar {...picture} />
      </Td>
      <Td>
        <H6 style={{ marginBottom: 0 }}>{`${firstName} ${lastName}`}</H6>
      </Td>
      <Td>
        <Text>{`${email}`}</Text>
      </Td>
      {isDj && (
        <Td>
          <Text>{`${playingLocation?.name}`}</Text>
        </Td>
      )}
      <Td>
        <Text>{`Member for ${moment(createdAt).fromNow(true)}`}</Text>
      </Td>
      {isDj && (
        <Td>
          <Tag
            large
            minimal
            round
            intent={
              profileStatus === EDIT_STATUS.APPROVED
                ? Intent.SUCCESS
                : profileStatus === EDIT_STATUS.REJECTED
                ? Intent.DANGER
                : Intent.WARNING
            }
          >
            {profileStatus}
          </Tag>
        </Td>
      )}
      <Td>
        <Tag
          large
          minimal
          round
          intent={emailVerified ? Intent.SUCCESS : Intent.DANGER}
        >
          {emailVerified ? "Email verified" : "Email not verified"}
        </Tag>
      </Td>
      <Td>
        <Tag
          large
          minimal
          round
          intent={standby ? Intent.WARNING : Intent.SUCCESS}
        >
          {standby ? "On standby" : "Active"}
        </Tag>
      </Td>
    </tr>
  );
};

export default Users;

export const DJs = (props) => {
  return <Users {...props} isDj={true} />;
};

export const Organizers = (props) => {
  return <Users {...props} isDj={false} />;
};
