import gql from "graphql-tag";

export const SEND_MARKETING_NOTIFICATION = gql`
  mutation scheduleMarketingNotification(
    $audience: MARKETING_AUDIENCE!
    $title: String!
    $message: String
    $data: JSON!
  ) {
    scheduleMarketingNotification(
      audience: $audience
      title: $title
      message: $message
      data: $data
    )
  }
`;
