import React from "react";
import InfoStat from "./InfoStat";
import { Icon, Text } from "@blueprintjs/core";

const CancelationPolicy = ({
	cancelationPolicy,
	loading,
	className,
	...props
}) => (
	<div
		className={"mt-auto flex flex-row justify-between items-end " + className}
		style={{ width: 400 }}
	>
		<InfoStat
			loading={loading}
			unit={"MIN. NOTICE"}
			val={cancelationPolicy.days + " days"}
		/>

		<Icon icon="arrow-right"></Icon>

		<Text className="text-gray-600 tracking-wide font-semibold">OR ELSE</Text>

		<Icon icon="arrow-right"></Icon>

		<InfoStat
			loading={loading}
			unit={"REFUNDED"}
			val={cancelationPolicy.percentage + "%"}
		/>
	</div>
);

export default CancelationPolicy;
