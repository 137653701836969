import gql from "graphql-tag";

const CHATS = gql`
	query Chats(
		$pagination: Pagination!
	) {
		chats(
			pagination: $pagination
		) {
			pageInfo {
				page
				prevPage
				nextPage
				totalPages
				totalDocs
			}
			edges {
				id
				count
				lastMessageDate
				gig {
					event{
						name
					}
				}
			}
		}
	}
`;

export { CHATS };
