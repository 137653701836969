import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  BigInt: any;
  URL: any;
  EmailAddress: any;
  LocalDate: any;
  Upload: any;
  Date: any;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  affiliateContent: AffiliateContent;
  affiliatePayouts: Array<AffiliatePayout>;
  /** @deprecated use getManageBusinessUrl */
  availableBankCountries: Array<BankCountry>;
  chats: ChatsResult;
  djsInLocation: UsersResult;
  event?: Maybe<Event>;
  eventAdmin?: Maybe<Event>;
  events: EventResult;
  featuredDjs: Array<User>;
  getGeneratedNamesCount: Scalars['Int'];
  getLocationPage?: Maybe<LocationLandingPage>;
  getLocationPages: Array<LocationLandingPage>;
  getManageBusinessUrl: Scalars['String'];
  getMapDataForLocation?: Maybe<MapData>;
  getMedia?: Maybe<Media>;
  getPaymentAmount: Money;
  gig?: Maybe<Gig>;
  locationAveragePricing: LocationAveragePricing;
  manageSubscription: Scalars['String'];
  me?: Maybe<User>;
  metrics: Metrics;
  myAffiliateContent: Array<AffiliateContent>;
  myChats: ChatsResult;
  myGigs: GigResult;
  opportunities: OpportunityResult;
  opportunity: Opportunity;
  payoutMethod?: Maybe<PayoutMethod>;
  requestCheckoutURL: CheckoutIntent;
  requestEventPaymentIntent: StripePaymentIntent;
  searchDjs: UsersResult;
  sound?: Maybe<Sound>;
  soundFeed: SoundsResult;
  subscriptionTiers: Array<SubscriptionTier>;
  user?: Maybe<User>;
  userReferralContent?: Maybe<AffiliateContent>;
  users: UsersResult;
  userSettings: UserSettings;
  userSounds: SoundsResult;
};


export type QueryAffiliateContentArgs = {
  countClick?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type QueryChatsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryDjsInLocationArgs = {
  filter: SearchFilter;
  pagination: Pagination;
  version?: InputMaybe<Scalars['String']>;
};


export type QueryEventArgs = {
  hash: Scalars['String'];
  id: Scalars['ID'];
};


export type QueryEventAdminArgs = {
  id: Scalars['ID'];
};


export type QueryEventsArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryFeaturedDjsArgs = {
  countryCode?: InputMaybe<Scalars['String']>;
};


export type QueryGetLocationPageArgs = {
  slug: Scalars['String'];
};


export type QueryGetLocationPagesArgs = {
  admType?: InputMaybe<AdmType>;
  categoryType?: InputMaybe<LandingPageCategoryType>;
  iso3?: InputMaybe<Scalars['String']>;
};


export type QueryGetManageBusinessUrlArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  portalType?: InputMaybe<Business_Portal_Type>;
  returnUrl?: InputMaybe<Scalars['URL']>;
};


export type QueryGetMapDataForLocationArgs = {
  location?: InputMaybe<Area>;
  multiCountry?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetMediaArgs = {
  id: Scalars['ID'];
};


export type QueryGetPaymentAmountArgs = {
  amount: Scalars['Int'];
  currency: Currency;
  djId?: InputMaybe<Scalars['ID']>;
  paymentType: PayoutType;
};


export type QueryGigArgs = {
  hash?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type QueryLocationAveragePricingArgs = {
  currency?: InputMaybe<Scalars['String']>;
  durationHours?: InputMaybe<Scalars['Int']>;
  location: Area;
};


export type QueryManageSubscriptionArgs = {
  redirectUrl: Scalars['String'];
};


export type QueryMetricsArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  location?: InputMaybe<Area>;
  start?: InputMaybe<Scalars['DateTime']>;
};


export type QueryMyChatsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryMyGigsArgs = {
  filter?: InputMaybe<GigFilter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryOpportunitiesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryOpportunityArgs = {
  eventId: Scalars['ID'];
};


export type QueryPayoutMethodArgs = {
  id: Scalars['ID'];
};


export type QueryRequestCheckoutUrlArgs = {
  cancelUrl?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currency>;
  gigId: Scalars['ID'];
  locale?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PayoutType>;
};


export type QueryRequestEventPaymentIntentArgs = {
  currency?: InputMaybe<Currency>;
  gigId: Scalars['ID'];
  locale?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PayoutType>;
};


export type QuerySearchDjsArgs = {
  filter: SearchFilter;
  pagination: Pagination;
  version?: InputMaybe<Scalars['String']>;
};


export type QuerySoundArgs = {
  id: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};


export type QuerySoundFeedArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QuerySubscriptionTiersArgs = {
  includeLifetime?: InputMaybe<Scalars['Boolean']>;
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
  permalink?: InputMaybe<Scalars['String']>;
};


export type QueryUserReferralContentArgs = {
  permalink: Scalars['ID'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<Filter>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryUserSettingsArgs = {
  id: Scalars['ID'];
};


export type QueryUserSoundsArgs = {
  pagination?: InputMaybe<Pagination>;
  userId: Scalars['ID'];
};

export type AffiliateContent = {
  __typename?: 'AffiliateContent';
  bookings?: Maybe<Scalars['Int']>;
  clicks?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  earnings?: Maybe<Money>;
  events?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: AffiliateContentType;
  url: Scalars['URL'];
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['BigInt'];
  currency: Currency;
  formatted?: Maybe<Scalars['String']>;
};


export type MoneyFormattedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export enum Currency {
  Aed = 'AED',
  Aud = 'AUD',
  Cad = 'CAD',
  Chf = 'CHF',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Idr = 'IDR',
  Jpy = 'JPY',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Pln = 'PLN',
  Sek = 'SEK',
  Sgd = 'SGD',
  Usd = 'USD'
}

export enum AffiliateContentType {
  BookingForm = 'BOOKING_FORM',
  Category = 'CATEGORY',
  Location = 'LOCATION',
  Referral = 'REFERRAL',
  Search = 'SEARCH',
  User = 'USER'
}

export type AffiliatePayout = {
  __typename?: 'AffiliatePayout';
  gigState: GigStatus;
  id: Scalars['ID'];
  payoutAmount: Money;
  payoutDate: Scalars['DateTime'];
};

/**
 * REQUESTED: No offer yet
 * ACCEPTED: Offer has been made
 * DECLINED: Declined, dj not interested
 * CANCELLED: Gig has been canceled
 * EVENT_CANCELLED: Event has been cancelled
 * LOST: Another Dj has been chosen
 * CONFIRMED: The customer has chosen and payed this
 * FINISHED:  The gig is played and finished
 * ORGANIZER_DECLINED: The organizer has declined the DJ
 * PASS: The DJ has passed the gig opportunity
 */
export enum GigStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Declined = 'DECLINED',
  EventCancelled = 'EVENT_CANCELLED',
  Finished = 'FINISHED',
  Initial = 'INITIAL',
  Lost = 'LOST',
  OrganizerDeclined = 'ORGANIZER_DECLINED',
  Pass = 'PASS',
  Requested = 'REQUESTED'
}

export type BankCountry = {
  __typename?: 'BankCountry';
  countryCode: Scalars['String'];
  defaultCurrency?: Maybe<Currency>;
  defaultCurrency_raw?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['JSON']>;
  raw?: Maybe<Scalars['JSON']>;
  supportedCurrencies: Array<Currency>;
  supportedCurrencies_raw: Array<Scalars['String']>;
  supportsIdentityVerification?: Maybe<Scalars['Boolean']>;
};

/**
 * Cursor is recieved in the PageInfo from last query.
 * If cursor is not entered the query will return elements from the beginning.
 */
export type Pagination = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
};

export enum OrderBy {
  CreatedAtAscending = 'CREATED_AT_ASCENDING',
  CreatedAtDescending = 'CREATED_AT_DESCENDING',
  OrderKey = 'ORDER_KEY',
  StartTime = 'START_TIME',
  StartTimeDescending = 'START_TIME_DESCENDING',
  UpdatedAtAscending = 'UPDATED_AT_ASCENDING',
  UpdatedAtDescending = 'UPDATED_AT_DESCENDING'
}

export type ChatsResult = {
  __typename?: 'ChatsResult';
  edges: Array<Chat>;
  pageInfo: PageInfo;
};

export type Chat = {
  __typename?: 'Chat';
  count: Scalars['Int'];
  firstMessageDate?: Maybe<Scalars['DateTime']>;
  gig?: Maybe<Gig>;
  hasUnread?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastMessageDate?: Maybe<Scalars['DateTime']>;
  latestMessage?: Maybe<Message>;
  messages: Array<Message>;
  /** When logged in will be set to the other user in the chat. */
  user?: Maybe<User>;
  users: Array<User>;
};

export type Gig = {
  __typename?: 'Gig';
  affiliateLinkId?: Maybe<Scalars['ID']>;
  attachments?: Maybe<Array<File>>;
  availablePayoutMethods: Array<PayoutMethod>;
  chatInitiated?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  directBooking: Scalars['Boolean'];
  discount?: Maybe<Scalars['Boolean']>;
  dj?: Maybe<User>;
  event: Event;
  expires?: Maybe<Scalars['DateTime']>;
  hasUnreadMessage?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isActionable: Scalars['Boolean'];
  lastChatMessage?: Maybe<Message>;
  messageToGuests?: Maybe<Scalars['String']>;
  offer?: Maybe<Offer>;
  opportunity?: Maybe<Scalars['Boolean']>;
  organizer?: Maybe<User>;
  reason?: Maybe<Scalars['String']>;
  referred?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Review>;
  seenByDJ?: Maybe<Scalars['Int']>;
  seenByOrganizer?: Maybe<Scalars['Int']>;
  status: GigStatus;
  statusHumanized: Scalars['String'];
};


export type GigStatusHumanizedArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  path: Scalars['String'];
};

export type PayoutMethod = {
  id: Scalars['ID'];
  paymentProvider: PaymentProvider;
  payoutType: PayoutType;
};

export enum PaymentProvider {
  Direct = 'DIRECT',
  Stripe = 'STRIPE'
}

export enum PayoutType {
  Bank = 'BANK',
  Direct = 'DIRECT'
}

export type User = {
  __typename?: 'User';
  appMetadata: AppMetadata;
  artistName?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  edits: Array<UserEdit>;
  email: Scalars['EmailAddress'];
  events: EventResult;
  eventTypes?: Maybe<Array<Scalars['String']>>;
  featuredIframe?: Maybe<Scalars['String']>;
  genres?: Maybe<Array<Scalars['String']>>;
  gigs: GigResult;
  highlightedReview?: Maybe<Review>;
  id: Scalars['ID'];
  isOwn: Scalars['Boolean'];
  media: MediaResult;
  onePage: OnePage;
  payoutMethods: Array<PayoutMethod>;
  permalink: Scalars['String'];
  picture: Media;
  playedVenues?: Maybe<Array<Scalars['String']>>;
  playingLocation?: Maybe<PlayingLocation>;
  playingLocations: Array<PlayingLocation>;
  pricing?: Maybe<ServicePricing>;
  responseMetrics?: Maybe<ResponseMetrics>;
  reviews: ReviewResult;
  services?: Maybe<Array<ServicePrice>>;
  sounds: SoundsResult;
  userMetadata: UserMetadata;
  userSettings: UserSettings;
};


export type UserEventsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserGigsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserMediaArgs = {
  mediaType?: InputMaybe<MediaType>;
  pagination?: InputMaybe<Pagination>;
};


export type UserReviewsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type UserSoundsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type AppMetadata = {
  __typename?: 'AppMetadata';
  affiliateTermsAgreed: Scalars['Boolean'];
  approved: Scalars['Boolean'];
  banned: Scalars['Boolean'];
  businessType?: Maybe<Scalars['String']>;
  canGetTrial: Scalars['Boolean'];
  certified: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  earned?: Maybe<Money>;
  emailVerified: Scalars['Boolean'];
  experience?: Maybe<Scalars['Int']>;
  experienceLevel?: Maybe<Scalars['String']>;
  followers?: Maybe<Followers>;
  hasInstalledApp: Scalars['Boolean'];
  identityStatus?: Maybe<IdentityStatus>;
  identityVerified: Scalars['Boolean'];
  instagramConnected: Scalars['Boolean'];
  instagramUsername?: Maybe<Scalars['String']>;
  internalRating?: Maybe<Scalars['Int']>;
  isMe: Scalars['Boolean'];
  isPro: Scalars['Boolean'];
  lastActiveAt?: Maybe<Scalars['DateTime']>;
  mixcloudConnected: Scalars['Boolean'];
  mixcloudUrl?: Maybe<Scalars['String']>;
  mixcloudUsername?: Maybe<Scalars['String']>;
  onboarded: Scalars['Boolean'];
  profileStatus: EditStatus;
  rating?: Maybe<Scalars['Float']>;
  reviewsCount?: Maybe<Scalars['Int']>;
  roles: Array<Role>;
  soundCloudConnected: Scalars['Boolean'];
  soundCloudUrl?: Maybe<Scalars['String']>;
  soundCloudUsername?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  useLegacyPayout: Scalars['Boolean'];
};

export type Followers = {
  __typename?: 'Followers';
  instagram?: Maybe<Scalars['Int']>;
  mixcloud?: Maybe<Scalars['Int']>;
  soundcloud?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  totalFormatted?: Maybe<Scalars['String']>;
};

export type IdentityStatus = {
  __typename?: 'IdentityStatus';
  details?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export enum EditStatus {
  Approved = 'APPROVED',
  Null = 'NULL',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum Role {
  Admin = 'ADMIN',
  Dj = 'DJ',
  Organizer = 'ORGANIZER'
}

export type UserEdit = {
  __typename?: 'UserEdit';
  createdAt: Scalars['DateTime'];
  fieldName: Scalars['String'];
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  status: EditStatus;
  value?: Maybe<Scalars['String']>;
};

export type EventResult = {
  __typename?: 'EventResult';
  edges: Array<Event>;
  pageInfo: PageInfo;
};

export type Event = {
  __typename?: 'Event';
  address?: Maybe<Scalars['String']>;
  /** @deprecated Use minBudget and maxBudget */
  budget?: Maybe<Money>;
  chatInitiated?: Maybe<Scalars['Boolean']>;
  chosenGig?: Maybe<Gig>;
  contactEmail?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  createdAt: TimezoneDate;
  description?: Maybe<Scalars['String']>;
  duration: TimeDuration;
  enableSongWishes?: Maybe<Scalars['Boolean']>;
  end: TimezoneDate;
  eventType?: Maybe<Array<Scalars['String']>>;
  flagged?: Maybe<Scalars['Boolean']>;
  flaggedReason?: Maybe<Scalars['String']>;
  genres: Array<Scalars['String']>;
  gigs: Array<Gig>;
  gigsPaginated: GigResult;
  guestsCount: Scalars['Int'];
  hash: Scalars['String'];
  id: Scalars['ID'];
  location: Location;
  maxBudget?: Maybe<Money>;
  minBudget?: Maybe<Money>;
  name: Scalars['String'];
  organizer?: Maybe<User>;
  payment?: Maybe<Scalars['JSON']>;
  potentialDjs: UsersResult;
  requestedServices: Array<ServiceRequest>;
  review?: Maybe<Review>;
  /** @deprecated Use services */
  rider: Rider;
  songWishes?: Maybe<Scalars['JSON']>;
  start: TimezoneDate;
  status: EventStatus;
  timeZone?: Maybe<Scalars['String']>;
};


export type EventGigsPaginatedArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type EventPotentialDjsArgs = {
  pagination?: InputMaybe<Pagination>;
  version?: InputMaybe<Scalars['String']>;
};

export type TimezoneDate = {
  __typename?: 'TimezoneDate';
  formattedDate: Scalars['String'];
  formattedTime: Scalars['String'];
  localDate: Scalars['String'];
  timezone: Scalars['String'];
  UTC: Scalars['DateTime'];
};


export type TimezoneDateFormattedDateArgs = {
  locale?: InputMaybe<Scalars['String']>;
};


export type TimezoneDateFormattedTimeArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type TimeDuration = {
  __typename?: 'TimeDuration';
  formatted: Scalars['String'];
  hours: Scalars['Int'];
  humanized: Scalars['String'];
  minutes: Scalars['Int'];
  seconds: Scalars['Int'];
  totalSeconds: Scalars['Int'];
};

export type GigResult = {
  __typename?: 'GigResult';
  edges: Array<Gig>;
  pageInfo: PageInfo;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  nextPage?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  prevPage?: Maybe<Scalars['Int']>;
  totalDocs?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Location = {
  __typename?: 'Location';
  /** @deprecated Use PlayingLocation */
  latitude: Scalars['Float'];
  /** @deprecated Use PlayingLocation */
  longitude: Scalars['Float'];
  /** @deprecated Use PlayingLocation */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use PlayingLocation */
  radius?: Maybe<Scalars['Float']>;
};

export type UsersResult = {
  __typename?: 'UsersResult';
  edges: Array<User>;
  pageInfo: PageInfo;
};

export type ServiceRequest = {
  __typename?: 'ServiceRequest';
  builtInId?: Maybe<Scalars['String']>;
  label: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  author?: Maybe<User>;
  citation?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  event?: Maybe<Event>;
  id: Scalars['ID'];
  isTestimonial: Scalars['Boolean'];
  rating?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Rider = {
  __typename?: 'Rider';
  formatted: Scalars['String'];
  lights: Scalars['Boolean'];
  microphone: Scalars['Boolean'];
  smokeMachine: Scalars['Boolean'];
  speakers: Scalars['Boolean'];
};

/**
 * INITIAL: No gigs generated yet
 * ACCEPTED: At least one offer has been made
 * OFFERING: Gigs are created but none have offered
 * CANCELLED: Event has been canceled
 * CONFIRMED: The customer has confirmed and payed an offer
 * FINISHED:  The event is played and finished
 * NO_MATCHES: No djs could be found
 */
export enum EventStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Finished = 'FINISHED',
  Initial = 'INITIAL',
  NoMatches = 'NO_MATCHES',
  Offering = 'OFFERING'
}

export enum MediaType {
  Application = 'APPLICATION',
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type MediaResult = {
  __typename?: 'MediaResult';
  edges: Array<Media>;
  pageInfo: PageInfo;
};

export type Media = {
  __typename?: 'Media';
  base64?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  encoding: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  orderBy?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  ratio: Scalars['Float'];
  type: MediaType;
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
};

export type OnePage = {
  __typename?: 'OnePage';
  enableBookingLink?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  links: Array<OnePageLink>;
  showLatestMix?: Maybe<Scalars['Boolean']>;
  tagline?: Maybe<Scalars['String']>;
  theme?: Maybe<OnePageTheme>;
};

export type OnePageLink = {
  __typename?: 'OnePageLink';
  brand?: Maybe<Scalars['String']>;
  category: OnePageLinkCategory;
  label: Scalars['String'];
  link?: Maybe<Scalars['String']>;
};

export enum OnePageLinkCategory {
  Contact = 'CONTACT',
  Custom = 'CUSTOM',
  Follow = 'FOLLOW',
  Listen = 'LISTEN'
}

export enum OnePageTheme {
  Dark = 'DARK',
  Light = 'LIGHT',
  System = 'SYSTEM'
}

export type PlayingLocation = {
  __typename?: 'PlayingLocation';
  admAreas?: Maybe<Array<Scalars['String']>>;
  country?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  iso3?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  multiCountry?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Float']>;
  toDate?: Maybe<Scalars['DateTime']>;
};

export type ServicePricing = {
  __typename?: 'ServicePricing';
  hourlyRate: Money;
  instantOffer: Scalars['Boolean'];
  minPrice?: Maybe<Money>;
  requirements?: Maybe<Array<Maybe<ArtistRequirement>>>;
  restrictToEventTypes: Scalars['Boolean'];
  restrictToNearbyEvents: Scalars['Boolean'];
  travelHourlyRate?: Maybe<Money>;
};

export type ArtistRequirement = {
  __typename?: 'ArtistRequirement';
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
};

export type ResponseMetrics = {
  __typename?: 'ResponseMetrics';
  avgResponseTime?: Maybe<Scalars['Float']>;
  responseRate?: Maybe<Scalars['Float']>;
};

export type ReviewResult = {
  __typename?: 'ReviewResult';
  edges: Array<Review>;
  pageInfo: PageInfo;
};

export type ServicePrice = {
  __typename?: 'ServicePrice';
  builtInId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  price: Money;
  required: Scalars['Boolean'];
};

export type SoundsResult = {
  __typename?: 'SoundsResult';
  edges: Array<Sound>;
  pageInfo: PageInfo;
};

export type Sound = {
  __typename?: 'Sound';
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration: TimeDuration;
  file?: Maybe<Media>;
  id: Scalars['ID'];
  image?: Maybe<Media>;
  isBeingProcessed?: Maybe<Scalars['Boolean']>;
  mixcloudId?: Maybe<Scalars['ID']>;
  samples: Array<Scalars['Int']>;
  soundcloudId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['URL']>;
  user?: Maybe<User>;
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  address?: Maybe<Scalars['String']>;
  /** @deprecated use the new payoutMethods on user */
  bankAccount?: Maybe<Bank>;
  /** @deprecated use the new payoutMethods on user */
  bankCurrency?: Maybe<Currency>;
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  taxId?: Maybe<TaxId>;
  website?: Maybe<Scalars['URL']>;
};

export type Bank = PayoutMethod & {
  __typename?: 'Bank';
  accountHolderName: Scalars['String'];
  bankCode?: Maybe<Scalars['String']>;
  bankName: Scalars['String'];
  countryCode: Scalars['String'];
  currency: Currency;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last4: Scalars['Int'];
  paymentProvider: PaymentProvider;
  payoutType: PayoutType;
  status: Scalars['String'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  OptOut = 'OPT_OUT',
  Other = 'OTHER'
}

export type TaxId = {
  __typename?: 'TaxId';
  country?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  verification?: Maybe<Scalars['JSON']>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  cancelationPolicy?: Maybe<CancelationPolicy>;
  currency?: Maybe<Currency>;
  id: Scalars['ID'];
  notifications: Scalars['JSON'];
  publicDisplay: Scalars['JSON'];
  standby?: Maybe<Scalars['Boolean']>;
};

export type CancelationPolicy = {
  __typename?: 'CancelationPolicy';
  days: Scalars['Int'];
  percentage: Scalars['Int'];
};

export type Message = {
  __typename?: 'Message';
  containsEmail: Scalars['Boolean'];
  containsNumber: Scalars['Boolean'];
  containsURL: Scalars['Boolean'];
  content: Scalars['String'];
  date: Scalars['DateTime'];
  from?: Maybe<User>;
  id: Scalars['ID'];
  read: Scalars['Boolean'];
  to?: Maybe<User>;
};

export type Offer = {
  __typename?: 'Offer';
  amountLeft: Money;
  amountPaid: Money;
  availablePayoutMethods: Array<PayoutMethod>;
  bestCaseRefund?: Maybe<Money>;
  bookingConfirmedDate?: Maybe<Scalars['DateTime']>;
  canBePaid: Scalars['Boolean'];
  cancelationPolicy?: Maybe<CancelationPolicy>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentRefund: Money;
  daysLeftInCancelationPolicy: Scalars['Int'];
  daysUntilPaymentPossible: Scalars['Int'];
  discount: Scalars['Boolean'];
  djFee: Money;
  isWithinCancelationPolicy: Scalars['Boolean'];
  offer: Money;
  offerItems: Array<OfferItem>;
  paymentProvider?: Maybe<PaymentProvider>;
  payoutType?: Maybe<PayoutType>;
  serviceFee: Money;
  totalPayment: Money;
  totalPayout: Money;
  updatedAt?: Maybe<Scalars['DateTime']>;
  worstCaseRefund?: Maybe<Money>;
};


export type OfferAmountLeftArgs = {
  currency?: InputMaybe<Currency>;
};


export type OfferAmountPaidArgs = {
  currency?: InputMaybe<Currency>;
};


export type OfferBestCaseRefundArgs = {
  currency?: InputMaybe<Currency>;
};


export type OfferCurrentRefundArgs = {
  currency?: InputMaybe<Currency>;
};


export type OfferDjFeeArgs = {
  currency?: InputMaybe<Currency>;
};


export type OfferOfferArgs = {
  currency?: InputMaybe<Currency>;
};


export type OfferServiceFeeArgs = {
  currency?: InputMaybe<Currency>;
};


export type OfferTotalPaymentArgs = {
  currency?: InputMaybe<Currency>;
};


export type OfferTotalPayoutArgs = {
  currency?: InputMaybe<Currency>;
};


export type OfferWorstCaseRefundArgs = {
  currency?: InputMaybe<Currency>;
};

export type OfferItem = {
  __typename?: 'OfferItem';
  amount: Scalars['BigInt'];
  label: Scalars['String'];
};

export type SearchFilter = {
  budget?: InputMaybe<Budget>;
  countryCode?: InputMaybe<Scalars['String']>;
  eventTypes?: InputMaybe<Array<Scalars['String']>>;
  gender?: InputMaybe<Gender>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  location?: InputMaybe<Area>;
  services?: InputMaybe<Array<Scalars['String']>>;
};

export type Budget = {
  currency?: InputMaybe<Currency>;
  hours?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type Area = {
  admType?: InputMaybe<AdmType>;
  iso2?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['Float']>;
};

export enum AdmType {
  Adm0 = 'ADM0',
  Adm1 = 'ADM1',
  Adm2 = 'ADM2'
}

export type Filter = {
  eventStatus?: InputMaybe<Array<EventStatus>>;
  isDj?: InputMaybe<Scalars['Boolean']>;
  isOnStandby?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Area>;
  profileStatus?: InputMaybe<EditStatus>;
  search?: InputMaybe<Scalars['String']>;
};

export type LocationLandingPage = {
  __typename?: 'LocationLandingPage';
  admType: AdmType;
  boundariesURL?: Maybe<Scalars['String']>;
  category?: Maybe<LandingPageCategory>;
  center: Point;
  content_da?: Maybe<Scalars['JSON']>;
  content_en?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  iso2?: Maybe<Scalars['String']>;
  iso3: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<LocationLandingPage>;
  relatedLocations?: Maybe<Array<LocationLandingPage>>;
  slug: Scalars['String'];
};

export type LandingPageCategory = {
  __typename?: 'LandingPageCategory';
  name: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<LandingPageCategoryType>;
};

export enum LandingPageCategoryType {
  Birthday = 'BIRTHDAY',
  Corporate = 'CORPORATE',
  None = 'NONE',
  Wedding = 'WEDDING'
}

export type Point = {
  __typename?: 'Point';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum Business_Portal_Type {
  CreatePayout = 'CREATE_PAYOUT',
  ExpressDashboard = 'EXPRESS_DASHBOARD',
  IdentityVerification = 'IDENTITY_VERIFICATION',
  UpdatePayout = 'UPDATE_PAYOUT'
}

export type MapData = {
  __typename?: 'MapData';
  boundariesURL?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['JSON']>;
  country?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  iso3?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['JSON']>;
};

export type LocationAveragePricing = {
  __typename?: 'LocationAveragePricing';
  currency: Scalars['String'];
  djs: Array<User>;
  expectedMax: Scalars['Int'];
  expectedMin: Scalars['Int'];
  max: Scalars['Int'];
  median: Scalars['Int'];
  min: Scalars['Int'];
};

export type Metrics = {
  __typename?: 'Metrics';
  event: EventMetrics;
  gig: GigMetrics;
  user: UserMetrics;
};

export type EventMetrics = {
  __typename?: 'EventMetrics';
  acceptedDaily: LineChartData;
  acceptedPercentage: Comparable;
  confirmedPercentage: Comparable;
  count: Comparable;
  createdDaily: LineChartData;
  messageSeenPercentage: Comparable;
  withDjsPercentage: Comparable;
  withMessagePercentage: Comparable;
};

export type LineChartData = {
  __typename?: 'LineChartData';
  keys: Array<Scalars['String']>;
  lastPeriod: Array<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDateLastPeriod?: Maybe<Scalars['DateTime']>;
  values: Array<Scalars['Float']>;
};

export type Comparable = {
  __typename?: 'Comparable';
  difference: Scalars['Float'];
  lastPeriod: Scalars['Float'];
  percentage: Scalars['Float'];
  value: Scalars['Float'];
};

export type GigMetrics = {
  __typename?: 'GigMetrics';
  acceptedDaily: LineChartData;
  acceptedPercentage: Comparable;
  acceptedSeenPercentage: Comparable;
  count: Comparable;
  hasMessagedPercentage: Comparable;
  messageSeenPercentage: Comparable;
  seenPercentage: Comparable;
};

export type UserMetrics = {
  __typename?: 'UserMetrics';
  activeUsers: Comparable;
  djHeatmap: Array<Location>;
  hasAcceptedPercentage: Comparable;
  hasGigPercentage: Comparable;
  hasMessagedPercentage: Comparable;
  messagesReadPercentage: Comparable;
  messagesSent: Comparable;
  newDjs: Comparable;
  newOrganizers: Comparable;
  newUsers: Comparable;
  signupsDaily: LineChartData;
  userGrowth: LineChartData;
  userRetention: Array<Cohort>;
};

export type Cohort = {
  __typename?: 'Cohort';
  dataSeries?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
};

export type GigFilter = {
  afterDate?: InputMaybe<Scalars['DateTime']>;
  beforeDate?: InputMaybe<Scalars['DateTime']>;
  opportunity?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Array<GigStatus>>;
};

export type OpportunityResult = {
  __typename?: 'OpportunityResult';
  edges: Array<Opportunity>;
  pageInfo: PageInfo;
};

export type Opportunity = {
  __typename?: 'Opportunity';
  event?: Maybe<Event>;
  gig?: Maybe<Gig>;
};

export type CheckoutIntent = {
  __typename?: 'CheckoutIntent';
  amount: Money;
  offer: Offer;
  url: Scalars['URL'];
};

export type StripePaymentIntent = {
  __typename?: 'StripePaymentIntent';
  amount: Money;
  customerId: Scalars['String'];
  ephemeralKey?: Maybe<Scalars['String']>;
  gigId: Scalars['ID'];
  paymentProvider: PaymentProvider;
  token: Scalars['String'];
};

export type SubscriptionTier = {
  __typename?: 'SubscriptionTier';
  active: Scalars['Boolean'];
  beforePrice?: Maybe<Money>;
  beforePriceMonthly?: Maybe<Money>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Discount>;
  id: Scalars['ID'];
  interval: SubscriptionInterval;
  name: Scalars['String'];
  price: Money;
  priceMonthly?: Maybe<Money>;
};

export type Discount = {
  __typename?: 'Discount';
  amount: Money;
  code: Scalars['String'];
  duration: Scalars['String'];
};

export enum SubscriptionInterval {
  Lifetime = 'LIFETIME',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  acceptOpportunity: Gig;
  addMedia: Media;
  addPlayingLocation: PlayingLocation;
  addSound: Sound;
  agreeAffiliateTerms: Scalars['Boolean'];
  attachFileToGig: Gig;
  banUser: Scalars['Boolean'];
  calculateEventState: Scalars['Boolean'];
  cancelEvent: Event;
  cancelGig: Gig;
  changeEventState?: Maybe<Scalars['Boolean']>;
  changeState?: Maybe<Scalars['Boolean']>;
  cleanScheduler: Scalars['Boolean'];
  connectInstagram: Scalars['String'];
  connectMixcloud: Scalars['String'];
  connectSoundCloud: Scalars['String'];
  createAffiliateContent: AffiliateContent;
  createEvent: EventLogin;
  declineDJ: Gig;
  declineGig: Gig;
  deleteFile: Scalars['Boolean'];
  deleteMedia: Scalars['Boolean'];
  deleteServicePrice: Scalars['Boolean'];
  deleteStripeConnectAccount: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  disconnectInstagram: User;
  disconnectMixcloud: Scalars['Boolean'];
  disconnectSoundCloud: Scalars['Boolean'];
  djsAvailable: Scalars['Boolean'];
  doUpdateTypes: Scalars['Boolean'];
  emailTaken: Scalars['Boolean'];
  generateGigs: Scalars['Boolean'];
  generateGigsForUser: Scalars['Boolean'];
  getOffer: Offer;
  getUrlForUpload: SignedUpload;
  highlightReview: User;
  logActivity: Scalars['Boolean'];
  makeOffer: Offer;
  nameGenerated: Scalars['Int'];
  passOpportunity: Gig;
  payEvent: Event;
  removeAffiliateContent: Scalars['Boolean'];
  removePayoutMethod: Scalars['Boolean'];
  removePlayingLocation: Scalars['Boolean'];
  removePushToken: Scalars['Boolean'];
  removeReview: Scalars['Boolean'];
  removeSound: Scalars['Boolean'];
  reOpenSpamEvent: Scalars['Boolean'];
  requestPasswordReset: Scalars['Boolean'];
  requestVerifyEmail: Scalars['Boolean'];
  resetUserPassword: Token;
  reviewEdits?: Maybe<User>;
  savePushToken: Scalars['Boolean'];
  scheduleMarketingNotification: Scalars['Boolean'];
  sendEventToDj?: Maybe<Event>;
  sendFeedback: Scalars['Boolean'];
  signIn: Token;
  signUp: Scalars['Boolean'];
  signUpToken: Token;
  singleUpload: Media;
  startSubscription: StartSubscriptionResult;
  undoDeclineGig: Gig;
  undoPassOpportunity: Gig;
  updateEvent: Event;
  updateFile: Media;
  updateGig: Gig;
  updateMedia: Media;
  updateOnePage: OnePage;
  updatePayoutMethod: PayoutMethod;
  updatePhotosOrder: Array<Media>;
  updatePlayingLocation: PlayingLocation;
  updatePricing: User;
  updateSound: Sound;
  updateUser: User;
  updateUserSettings: UserSettings;
  upsertServicePrice: ServicePrice;
  verifyEmail: Scalars['Boolean'];
  writeReview: Review;
};


export type MutationAcceptOpportunityArgs = {
  eventId: Scalars['ID'];
};


export type MutationAddMediaArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  fileId?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationAddPlayingLocationArgs = {
  admAreas?: InputMaybe<Array<Scalars['String']>>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  iso3?: InputMaybe<Scalars['String']>;
  location: Area;
  multiCountry?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationAddSoundArgs = {
  addToMixcloud?: InputMaybe<Scalars['Boolean']>;
  addToSoundCloud?: InputMaybe<Scalars['Boolean']>;
  date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  file: Scalars['ID'];
  imageId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};


export type MutationAttachFileToGigArgs = {
  fileId: Scalars['ID'];
  gigId: Scalars['ID'];
};


export type MutationBanUserArgs = {
  id: Scalars['ID'];
  toggle: Scalars['Boolean'];
};


export type MutationCalculateEventStateArgs = {
  eventId: Scalars['ID'];
};


export type MutationCancelEventArgs = {
  hash: Scalars['String'];
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationCancelGigArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationChangeEventStateArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  eventId: Scalars['ID'];
  newState?: InputMaybe<EventStatus>;
};


export type MutationChangeStateArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  gigId: Scalars['ID'];
  newState?: InputMaybe<GigStatus>;
};


export type MutationConnectInstagramArgs = {
  code?: InputMaybe<Scalars['String']>;
  redirectLink?: InputMaybe<Scalars['String']>;
};


export type MutationConnectMixcloudArgs = {
  code?: InputMaybe<Scalars['String']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
};


export type MutationConnectSoundCloudArgs = {
  code?: InputMaybe<Scalars['String']>;
  redirectUrl?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAffiliateContentArgs = {
  input: CreateAffiliateContentInput;
};


export type MutationCreateEventArgs = {
  address?: InputMaybe<Scalars['String']>;
  awaitingPayment?: InputMaybe<Scalars['Boolean']>;
  budgetCurrency?: InputMaybe<Currency>;
  contactEmail: Scalars['String'];
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  djId?: InputMaybe<Scalars['ID']>;
  endMinute?: InputMaybe<Scalars['Int']>;
  eventType?: InputMaybe<Array<Scalars['String']>>;
  firstMessage?: InputMaybe<Scalars['String']>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  guestsCount?: InputMaybe<Scalars['Int']>;
  isFromPrivateLink?: InputMaybe<Scalars['Boolean']>;
  lights?: InputMaybe<Scalars['Boolean']>;
  location: Area;
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  openForAllDjs?: InputMaybe<Scalars['Boolean']>;
  requestedServices?: InputMaybe<Array<ServiceRequestInput>>;
  rider?: InputMaybe<RiderInput>;
  speakers?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  startMinute?: InputMaybe<Scalars['Int']>;
  timeZone?: InputMaybe<Scalars['String']>;
};


export type MutationDeclineDjArgs = {
  gigId: Scalars['ID'];
  hash: Scalars['String'];
};


export type MutationDeclineGigArgs = {
  flagEvent?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMediaArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteServicePriceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStripeConnectAccountArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDjsAvailableArgs = {
  date?: InputMaybe<Scalars['DateTime']>;
  location: Area;
};


export type MutationEmailTakenArgs = {
  email: Scalars['String'];
};


export type MutationGenerateGigsArgs = {
  eventId: Scalars['ID'];
};


export type MutationGenerateGigsForUserArgs = {
  userId: Scalars['ID'];
};


export type MutationGetOfferArgs = {
  amount: Scalars['Int'];
  currency: Currency;
  gigId: Scalars['ID'];
};


export type MutationGetUrlForUploadArgs = {
  fileName: Scalars['String'];
  mimeType?: InputMaybe<Scalars['String']>;
};


export type MutationHighlightReviewArgs = {
  id: Scalars['ID'];
  selection: Scalars['String'];
};


export type MutationLogActivityArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  subjectId: Scalars['ID'];
  subjectId2?: InputMaybe<Scalars['ID']>;
  type: Scalars['String'];
};


export type MutationMakeOfferArgs = {
  acceptedPayoutTypes?: InputMaybe<Array<PayoutType>>;
  amount: Scalars['Int'];
  currency: Currency;
  gigId: Scalars['ID'];
  offerItems?: InputMaybe<Array<OfferItemInput>>;
};


export type MutationNameGeneratedArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type MutationPassOpportunityArgs = {
  eventId: Scalars['ID'];
};


export type MutationPayEventArgs = {
  gigId: Scalars['ID'];
  paymentData: Scalars['JSON'];
  paymentProvider: PaymentProvider;
};


export type MutationRemoveAffiliateContentArgs = {
  id: Scalars['ID'];
};


export type MutationRemovePayoutMethodArgs = {
  id: Scalars['ID'];
};


export type MutationRemovePlayingLocationArgs = {
  id: Scalars['ID'];
};


export type MutationRemovePushTokenArgs = {
  token: Scalars['String'];
};


export type MutationRemoveReviewArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveSoundArgs = {
  id: Scalars['ID'];
  removeOnMixcloud?: InputMaybe<Scalars['Boolean']>;
  removeOnSoundCloud?: InputMaybe<Scalars['Boolean']>;
};


export type MutationReOpenSpamEventArgs = {
  eventId: Scalars['ID'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['EmailAddress'];
  redirectLink: Scalars['String'];
};


export type MutationRequestVerifyEmailArgs = {
  email: Scalars['EmailAddress'];
  redirectLink: Scalars['String'];
};


export type MutationResetUserPasswordArgs = {
  password: Scalars['String'];
  passwordResetToken: Scalars['String'];
};


export type MutationReviewEditsArgs = {
  internalRating?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<Array<ReviewEdit>>;
  userId: Scalars['ID'];
};


export type MutationSavePushTokenArgs = {
  token: Scalars['String'];
};


export type MutationScheduleMarketingNotificationArgs = {
  audience: Marketing_Audience;
  data: Scalars['JSON'];
  message?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationSendEventToDjArgs = {
  djId: Scalars['ID'];
  eventId: Scalars['ID'];
  firstMessage?: InputMaybe<Scalars['String']>;
};


export type MutationSendFeedbackArgs = {
  answer?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
};


export type MutationSignInArgs = {
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};


export type MutationSignUpArgs = {
  artistName?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailAddress'];
  experience?: InputMaybe<Scalars['String']>;
  experienceLevel?: InputMaybe<ExperienceLevel>;
  firstName?: InputMaybe<Scalars['String']>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  playingLocation?: InputMaybe<Area>;
  profilePicture?: InputMaybe<Scalars['ID']>;
  redirectLink?: InputMaybe<Scalars['String']>;
};


export type MutationSignUpTokenArgs = {
  artistName?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  email: Scalars['EmailAddress'];
  experience?: InputMaybe<Scalars['String']>;
  experienceLevel?: InputMaybe<ExperienceLevel>;
  firstName?: InputMaybe<Scalars['String']>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  playingLocation?: InputMaybe<Area>;
  profilePicture?: InputMaybe<Scalars['ID']>;
  redirectLink?: InputMaybe<Scalars['String']>;
};


export type MutationSingleUploadArgs = {
  file: Scalars['Upload'];
};


export type MutationStartSubscriptionArgs = {
  freeTrial?: InputMaybe<Scalars['Boolean']>;
  paymentData?: InputMaybe<PaymentData>;
  tierId: Scalars['ID'];
};


export type MutationUndoDeclineGigArgs = {
  gigId: Scalars['ID'];
};


export type MutationUndoPassOpportunityArgs = {
  eventId: Scalars['ID'];
};


export type MutationUpdateEventArgs = {
  address?: InputMaybe<Scalars['String']>;
  budgetCurrency?: InputMaybe<Currency>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  enableSongWishes?: InputMaybe<Scalars['Boolean']>;
  endMinute?: InputMaybe<Scalars['Int']>;
  eventType?: InputMaybe<Array<Scalars['String']>>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  guestsCount?: InputMaybe<Scalars['Int']>;
  hash: Scalars['String'];
  id: Scalars['ID'];
  location?: InputMaybe<Area>;
  maxPrice?: InputMaybe<Scalars['Int']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  requestedServices?: InputMaybe<Array<ServiceRequestInput>>;
  rider?: InputMaybe<RiderInput>;
  songWishes?: InputMaybe<Scalars['JSON']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
  startMinute?: InputMaybe<Scalars['Int']>;
  timeZone?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateFileArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateGigArgs = {
  gigId: Scalars['ID'];
  messageToGuests?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMediaArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  orderBy?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateOnePageArgs = {
  enableBookingLink?: InputMaybe<Scalars['Boolean']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  links?: InputMaybe<Array<Scalars['JSON']>>;
  showLatestMix?: InputMaybe<Scalars['Boolean']>;
  tagline?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<OnePageTheme>;
};


export type MutationUpdatePayoutMethodArgs = {
  data: Scalars['JSON'];
  provider: PaymentProvider;
  type: PayoutType;
};


export type MutationUpdatePhotosOrderArgs = {
  updates?: InputMaybe<Scalars['JSON']>;
};


export type MutationUpdatePlayingLocationArgs = {
  admAreas?: InputMaybe<Array<Scalars['String']>>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  iso3?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Area>;
  multiCountry?: InputMaybe<Scalars['Boolean']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpdatePricingArgs = {
  hourlyRate?: InputMaybe<Scalars['Int']>;
  instantOffer?: InputMaybe<Scalars['Boolean']>;
  minPrice?: InputMaybe<Scalars['Int']>;
  requirements?: InputMaybe<Array<ArtistRequirementInput>>;
  restrictToEventTypes?: InputMaybe<Scalars['Boolean']>;
  restrictToNearbyEvents?: InputMaybe<Scalars['Boolean']>;
  travelHourlyRate?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateSoundArgs = {
  date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  imageId?: InputMaybe<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  updateOnMixcloud?: InputMaybe<Scalars['Boolean']>;
  updateOnSoundCloud?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateUserArgs = {
  address?: InputMaybe<Scalars['String']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  artistName?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  cancelationDays?: InputMaybe<Scalars['Int']>;
  certified?: InputMaybe<Scalars['Boolean']>;
  city?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Currency>;
  email?: InputMaybe<Scalars['EmailAddress']>;
  eventTypes?: InputMaybe<Array<Scalars['String']>>;
  experience?: InputMaybe<Scalars['String']>;
  experienceLevel?: InputMaybe<ExperienceLevel>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  notificationSettings?: InputMaybe<Scalars['JSON']>;
  password?: InputMaybe<Scalars['String']>;
  paymentProvider?: InputMaybe<PaymentProvider>;
  payoutInfo?: InputMaybe<Scalars['JSON']>;
  permalink?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['Upload']>;
  playedVenues?: InputMaybe<Array<Scalars['String']>>;
  playingLocation?: InputMaybe<Area>;
  postalCode?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['ID']>;
  publicDisplaySettings?: InputMaybe<Scalars['JSON']>;
  redirectLink?: InputMaybe<Scalars['String']>;
  refundPercentage?: InputMaybe<Scalars['Int']>;
  standby?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  taxType?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['URL']>;
};


export type MutationUpdateUserSettingsArgs = {
  language?: InputMaybe<Scalars['ID']>;
};


export type MutationUpsertServicePriceArgs = {
  servicePrice: ServicePriceInput;
};


export type MutationVerifyEmailArgs = {
  verifyToken: Scalars['String'];
};


export type MutationWriteReviewArgs = {
  citation?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  gigId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  isTestimonial?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateAffiliateContentInput = {
  data: Scalars['JSON'];
  name: Scalars['String'];
};

export type ServiceRequestInput = {
  builtInId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  label: Scalars['String'];
};

export type RiderInput = {
  lights?: InputMaybe<Scalars['Boolean']>;
  microphone?: InputMaybe<Scalars['Boolean']>;
  smokeMachine?: InputMaybe<Scalars['Boolean']>;
  speakers?: InputMaybe<Scalars['Boolean']>;
};

export type EventLogin = {
  __typename?: 'EventLogin';
  event: Event;
  userJWT: Token;
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String'];
};

export type SignedUpload = {
  __typename?: 'SignedUpload';
  fields: Scalars['JSON'];
  fileId: Scalars['ID'];
  headers?: Maybe<Scalars['JSON']>;
  protocol?: Maybe<Scalars['String']>;
  url: Scalars['URL'];
};

export type OfferItemInput = {
  amount: Scalars['BigInt'];
  label: Scalars['String'];
};

export type ReviewEdit = {
  id?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  status: EditStatus;
};

export enum Marketing_Audience {
  DjNotPro = 'DJ_NOT_PRO'
}

export enum ExperienceLevel {
  Artist = 'ARTIST',
  Beginner = 'BEGINNER',
  Guru = 'GURU',
  Performer = 'PERFORMER',
  Profesional = 'PROFESIONAL',
  Upcoming = 'UPCOMING'
}

export type PaymentData = {
  paymentMethodId: Scalars['String'];
};

export type StartSubscriptionResult = {
  __typename?: 'StartSubscriptionResult';
  customerEphemeralKeySecret?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  paymentIntent?: Maybe<Scalars['JSON']>;
  requiresConfirmation: Scalars['Boolean'];
};

export type ArtistRequirementInput = {
  description?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  label?: InputMaybe<Scalars['String']>;
};

export type ServicePriceInput = {
  builtInId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']>;
  countUpdated: Scalars['Int'];
};

export type Direct = PayoutMethod & {
  __typename?: 'Direct';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  paymentProvider: PaymentProvider;
  payoutType: PayoutType;
  preferredCurrency?: Maybe<Currency>;
};

export type ReOpenSpamEventMutationVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type ReOpenSpamEventMutation = { __typename?: 'Mutation', reOpenSpamEvent: boolean };

export type DeleteStripeConnectAccountMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeleteStripeConnectAccountMutation = { __typename?: 'Mutation', deleteStripeConnectAccount: boolean };


export const ReOpenSpamEventDocument = gql`
    mutation ReOpenSpamEvent($eventId: ID!) {
  reOpenSpamEvent(eventId: $eventId)
}
    `;
export type ReOpenSpamEventMutationFn = Apollo.MutationFunction<ReOpenSpamEventMutation, ReOpenSpamEventMutationVariables>;

/**
 * __useReOpenSpamEventMutation__
 *
 * To run a mutation, you first call `useReOpenSpamEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReOpenSpamEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reOpenSpamEventMutation, { data, loading, error }] = useReOpenSpamEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useReOpenSpamEventMutation(baseOptions?: Apollo.MutationHookOptions<ReOpenSpamEventMutation, ReOpenSpamEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReOpenSpamEventMutation, ReOpenSpamEventMutationVariables>(ReOpenSpamEventDocument, options);
      }
export type ReOpenSpamEventMutationHookResult = ReturnType<typeof useReOpenSpamEventMutation>;
export type ReOpenSpamEventMutationResult = Apollo.MutationResult<ReOpenSpamEventMutation>;
export type ReOpenSpamEventMutationOptions = Apollo.BaseMutationOptions<ReOpenSpamEventMutation, ReOpenSpamEventMutationVariables>;
export const DeleteStripeConnectAccountDocument = gql`
    mutation DeleteStripeConnectAccount($userId: ID!) {
  deleteStripeConnectAccount(userId: $userId)
}
    `;
export type DeleteStripeConnectAccountMutationFn = Apollo.MutationFunction<DeleteStripeConnectAccountMutation, DeleteStripeConnectAccountMutationVariables>;

/**
 * __useDeleteStripeConnectAccountMutation__
 *
 * To run a mutation, you first call `useDeleteStripeConnectAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStripeConnectAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStripeConnectAccountMutation, { data, loading, error }] = useDeleteStripeConnectAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteStripeConnectAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStripeConnectAccountMutation, DeleteStripeConnectAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStripeConnectAccountMutation, DeleteStripeConnectAccountMutationVariables>(DeleteStripeConnectAccountDocument, options);
      }
export type DeleteStripeConnectAccountMutationHookResult = ReturnType<typeof useDeleteStripeConnectAccountMutation>;
export type DeleteStripeConnectAccountMutationResult = Apollo.MutationResult<DeleteStripeConnectAccountMutation>;
export type DeleteStripeConnectAccountMutationOptions = Apollo.BaseMutationOptions<DeleteStripeConnectAccountMutation, DeleteStripeConnectAccountMutationVariables>;