import React from "react";
import { HTMLTable, Text } from "@blueprintjs/core";

const LoadingTable = ({ rows, columns, className }) => {
	return (
		<HTMLTable className={className}>
			<tbody>
				{Array.from(Array(rows)).map((r, idx) => (
					<tr key={idx}>
						{Array.from(Array(columns)).map((c, jdx) => (
							<td key={jdx}>
								<Text className="bp3-skeleton h-12">Loading</Text>
							</td>
						))}
					</tr>
				))}
			</tbody>
		</HTMLTable>
	);
};

export default LoadingTable;
