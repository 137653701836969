import gql from "graphql-tag";

export const typeDefs = gql`
  type Location {
    latitude: Float
    longitude: Float
  }

  extend type Query {
    location: Location
  }
`;

export const resolvers = {};
export const defaultState = {
  location: null
};

const SEARCH_LOCATION = gql`
  query {
    location @client {
      latitude
      longitude
    }
  }
`;

export { SEARCH_LOCATION };
