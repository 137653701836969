import React, { useRef, useEffect } from "react";
import Chart from "chart.js";
import { Colors, H3 } from "@blueprintjs/core";
import moment from "moment";

Chart.defaults.global.defaultFontColor = Colors.GRAY5;

function LineChartEvent({ data, data2, label }) {
  const chart = useRef();

  useEffect(() => {
    const can = chart.current;

    if (data) {
      createChart({
        ctx: can,
        data,
        data2
      });

      return () => {
        const context = can.getContext("2d");
        context.clearRect(0, 0, can.width, can.height);
      };
    }
  }, [data, data2]);

  return (
    <div style={{ marginBottom: "30px", marginTop: "30px" }}>
      <H3 style={{ textAlign: "center" }}>{label}</H3>
      <canvas ref={chart} height={"400px"} />
    </div>
  );
}

const createChart = ({
  ctx,
  data,
  data2
  // startDate,
  // startDateLastPeriod,
  // values,
  // lastPeriod
}) => {
  return new Chart(ctx, {
    type: "line",
    height: 500,
    data: {
      datasets: [
        {
          label: "Accepted events",
          borderColor: "#3dcc91",
          backgroundColor: "#3dcc9110",
          data: data2.values,
          borderWidth: 2
        },
        {
          borderColor: "#31fff5",
          backgroundColor: "#31fff510",
          label: "Created events",
          data: data.values,
          borderWidth: 2
        },
        {
          label: "Last period Accepted",
          borderColor: "#fc8181",
          backgroundColor: "#fc818130",
          data: data2.lastPeriod,
          borderWidth: 1
        },
        {
          label: "Last period Created",
          data: data.lastPeriod,
          borderWidth: 1
        }
      ],
      labels: Array.from({ length: data.values.length }, (_, idx) =>
        moment(data.startDate)
          .add(idx, "days")
          .format("MMM DD")
      )
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      scales: {}
    }
  });
};

export default LineChartEvent;
