import gql from "graphql-tag";

const ME = gql`
	query Me {
		me {
			id
			email
			permalink
			genres
			picture {
				path
			}
			playingLocation {
				name
				radius
				longitude
				latitude
			}
			appMetadata {
				experience
				earned {
					amount
					currency
					formatted
				}
				roles
			}
			userMetadata {
				firstName
				lastName
				bio
				birthday
				phone
			}
			userSettings {
				id
				currency
				standby
				cancelationPolicy {
					days
					percentage
				}
			}
		}
	}
`;

const LOGIN = gql`
	mutation Signin($email: EmailAddress!, $password: String!) {
		signIn(email: $email, password: $password) {
			token
		}
	}
`;

export { LOGIN, ME };
