import { useQuery } from "@apollo/client";
import { H2 } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";
import LineChart from "./LineChart";
import MetricValue, { MetricGrid } from "./MetricValue";

function GigMetrics({ location, start, end }) {
  const { data, loading } = useQuery(METRICS, {
    variables: { location, start, end },
  });

  const { metrics = {} } = data || {};
  const { gig } = metrics;

  const {
    count,
    seenPercentage,
    acceptedPercentage,
    acceptedSeenPercentage,
    hasMessagedPercentage,
    messageSeenPercentage,
    acceptedDaily,
  } = gig || {};

  return (
    <div>
      <H2>Gig metrics</H2>
      <MetricGrid>
        <MetricValue
          loading={loading}
          metric={count}
          label={"Total gigs created"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={seenPercentage}
          label={"Gigs seen by DJ"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={acceptedPercentage}
          label={"Gigs accepted"}
        />

        <MetricValue
          isPercentage
          loading={loading}
          metric={acceptedSeenPercentage}
          label={"Offer seen by organizer"}
        />

        <MetricValue
          isPercentage
          loading={loading}
          metric={hasMessagedPercentage}
          label={"DJ sent message"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={messageSeenPercentage}
          label={"Message seen by organizer"}
        />
      </MetricGrid>

      <LineChart data={acceptedDaily} label={"Accepted gigs"} />
    </div>
  );
}

export default GigMetrics;

const METRICS = gql`
  query ($location: Area, $start: DateTime!, $end: DateTime!) {
    metrics(location: $location, start: $start, end: $end) {
      gig {
        count {
          value
          percentage
        }
        seenPercentage {
          value
          difference
        }
        acceptedPercentage {
          value
          difference
        }
        acceptedSeenPercentage {
          value
          difference
        }
        hasMessagedPercentage {
          value
          difference
        }
        messageSeenPercentage {
          value
          difference
        }

        acceptedDaily {
          values
          lastPeriod
          startDate
          startDateLastPeriod
        }
      }
    }
  }
`;
