import { useQuery } from "@apollo/client";
import { H2, H3 } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";
import ReactCohortGraph from "react-cohort-graph";
import Map from "../../Components/Map";
import LineChart from "./LineChart";
import MetricValue, { MetricGrid } from "./MetricValue";

function UserMetrics({ location, start, end }) {
  const { data, loading } = useQuery(METRICS, {
    variables: { location, start, end },
  });

  const { metrics = {} } = data || {};
  const { user } = metrics;

  const {
    newUsers,
    activeUsers,
    newDjs,
    newOrganizers,
    hasGigPercentage,
    hasAcceptedPercentage,
    hasMessagedPercentage,
    messagesSent,
    messagesReadPercentage,
    signupsDaily,
    djHeatmap = [],
    userRetention,
    userGrowth,
  } = user || {};

  const heatmapData = djHeatmap.map(({ longitude, latitude, radius }) => ({
    location: new window.google.maps.LatLng(latitude, longitude),
  }));

  return (
    <div>
      <H2>User metrics</H2>
      <MetricGrid>
        <MetricValue
          loading={loading}
          metric={newUsers}
          label={"Total new users"}
        />
        <MetricValue
          loading={loading}
          metric={activeUsers}
          label={"Active users"}
        />
        <MetricValue
          loading={loading}
          metric={newDjs}
          label={"Total new DJs"}
        />
        <MetricValue
          loading={loading}
          metric={newOrganizers}
          label={"Total new Organizers"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={hasGigPercentage}
          label={"New DJs that has gigs"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={hasAcceptedPercentage}
          label={"New DJs that has accepted gigs"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={hasMessagedPercentage}
          label={"New DJs that has sent messages"}
        />

        <MetricValue
          loading={loading}
          metric={messagesSent}
          label={"Total messages sent"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={messagesReadPercentage}
          label={"Messages read"}
        />
      </MetricGrid>
      <LineChart data={signupsDaily} label={"DJ signups"} />
      <LineChart data={userGrowth} label={"Users"} />

      <H3 style={{ textAlign: "center" }}>DJ Retention</H3>
      <DJRetention data={userRetention} />

      <div
        style={{ height: "80vh", marginBottom: "60px" }}
        className=" w-full flex flex-col"
      >
        <div className="flex flex-1">
          <Map heatmapData={heatmapData} defaultZoom={2} />
        </div>
      </div>
    </div>
  );
}

export default UserMetrics;

const METRICS = gql`
  query ($location: Area, $start: DateTime!, $end: DateTime!) {
    metrics(location: $location, start: $start, end: $end) {
      user {
        newUsers {
          value
          percentage
        }
        activeUsers {
          value
          percentage
        }
        newDjs {
          value
          percentage
        }
        newOrganizers {
          value
          percentage
        }
        hasGigPercentage {
          value
          difference
        }
        hasAcceptedPercentage {
          value
          difference
        }
        hasMessagedPercentage {
          value
          difference
        }
        messagesSent {
          value
          percentage
        }
        messagesReadPercentage {
          value
          difference
        }
        signupsDaily {
          values
          lastPeriod
          startDate
          startDateLastPeriod
        }
        userGrowth {
          values
          keys
        }
        djHeatmap {
          radius
          longitude
          latitude
        }
        userRetention {
          name
          dataSeries
        }
      }
    }
  }
`;

const DJRetention = ({ data }) => {
  if (!data) {
    return null;
  }

  const weeks = data.reduce(
    (acc, d) => ({ ...acc, [d.name]: d.dataSeries }),
    {}
  );

  return (
    <ReactCohortGraph
      showHeaderValues
      data={{ weeks }}
      wrapperStyles={{ color: "#111" }}
    />
  );
};
