import React from 'react'
import 'rsuite/dist/styles/rsuite-dark.css';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

const DateRange = (props) => {
    const today = moment().startOf('day');

    return (
        <DateRangePicker
        placement={'leftStart'}
        ranges={[ {
            label: 'Last 30 days',
            value: [today, moment(today).subtract(30, 'day')]
            
          },
          {
            label: 'Last 14 days',
            value: [today, moment(today).subtract(14, 'day')]
          },
          {
            label: 'Last 7 days',
            value: [today, moment(today).subtract(7, 'day')]
          },
          {
            label: 'Today',
            value: [today, moment(today).add(1, 'day')]
          }]}
          
        {...props} 
        
        />
    )
}

export default DateRange;