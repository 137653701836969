import gql from "graphql-tag";

const ALL_EVENTS = gql`
	query AllEvents(
		$pagination: Pagination!
		$search: String
		$location: Area
		$eventStatus: [EventStatus!]
	) {
		events(
			pagination: $pagination
			filter: {
				search: $search
				location: $location
				eventStatus: $eventStatus
			}
		) {
			pageInfo {
				page
				prevPage
				nextPage
				totalPages
				totalDocs
			}
			edges {
				id
				name
				status
				contactName
				contactEmail
				guestsCount
				review {
					id
				}
				start {
					UTC
					formattedDate
					formattedTime
				}
				end {
					UTC
					formattedDate
					formattedTime
				}
				duration {
					humanized
				}
				location {
					name
				}
			}
		}
	}
`;

export { ALL_EVENTS };
