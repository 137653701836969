import { useState } from "react";


const savedLocations = {}

const usePagination = ({id}) => {
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);

    const [page, set] = useState(savedLocations[id] || searchParams.get("page") || 1);

    const setPage = (p) => {
        savedLocations[id] = p;
        set(p);
    }

    return {
        page, setPage
    }
}

export default usePagination;