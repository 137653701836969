import { useQuery } from "@apollo/client";
import { H2 } from "@blueprintjs/core";
import gql from "graphql-tag";
import React from "react";
import LineChartEvent from "./LineChartEvents";
import MetricValue, { MetricGrid } from "./MetricValue";

function EventMetrics({ location, start, end }) {
  const { data, loading } = useQuery(METRICS, {
    variables: { location, start, end },
  });

  const { metrics = {} } = data || {};
  const { event } = metrics;

  const {
    count,
    withDjsPercentage,
    acceptedPercentage,
    confirmedPercentage,
    withMessagePercentage,
    messageSeenPercentage,
    createdDaily,
    acceptedDaily,
  } = event || {};

  return (
    <div>
      <H2>Event metrics</H2>
      <MetricGrid>
        <MetricValue
          loading={loading}
          metric={count}
          label={"Total events created"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={withDjsPercentage}
          label={"New events with DJs"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={acceptedPercentage}
          label={"New events accepted"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={confirmedPercentage}
          label={"New events confirmed"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={withMessagePercentage}
          label={"New events with message"}
        />
        <MetricValue
          isPercentage
          loading={loading}
          metric={messageSeenPercentage}
          label={"New events where messages seen"}
        />
      </MetricGrid>
      <LineChartEvent
        data={createdDaily}
        data2={acceptedDaily}
        label={"Created events vs. Accepted events"}
      />
    </div>
  );
}

export default EventMetrics;

const METRICS = gql`
  query ($location: Area, $start: DateTime!, $end: DateTime!) {
    metrics(location: $location, start: $start, end: $end) {
      event {
        count {
          value
          percentage
        }
        withDjsPercentage {
          value
          difference
        }
        acceptedPercentage {
          value
          difference
        }
        confirmedPercentage {
          value
          difference
        }
        withMessagePercentage {
          value
          difference
        }
        messageSeenPercentage {
          value
          difference
        }

        createdDaily {
          values
          lastPeriod
          startDate
          startDateLastPeriod
        }

        acceptedDaily {
          values
          lastPeriod
          startDate
          startDateLastPeriod
        }
      }
    }
  }
`;
