import gql from "graphql-tag";

const EVENT_DETAIL = gql`
  query EventDetail($id: ID!) {
    eventAdmin(id: $id) {
      id
      name
      status
      flagged
      flaggedReason
      contactName
      contactEmail
      contactPhone
      guestsCount
      description
      rider {
        formatted
      }
      hash
      genres
      createdAt {
        UTC
      }
      review {
        id
      }
      start {
        UTC
        formattedDate
        formattedTime
      }
      end {
        UTC
        formattedDate
        formattedTime
      }
      duration {
        humanized
      }
      location {
        name
        longitude
        latitude
      }
    }
  }
`;

const EVENT_GIGS = gql`
  query EventGigs($id: ID!, $pagination: Pagination!) {
    eventAdmin(id: $id) {
      id
      gigsPaginated(pagination: $pagination) {
        edges {
          id
          status
          statusHumanized
          directBooking
          seenByDJ
          seenByOrganizer
          chatInitiated
          dj {
            id
            email
            picture {
              id
              path
            }
            userMetadata {
              firstName
            }
          }
          event {
            id
            name
            start {
              UTC
            }
          }
          offer {
            totalPayment {
              amount
              formatted
            }
          }
        }
        pageInfo {
          page
          prevPage
          nextPage
          totalPages
          totalDocs
        }
      }
    }
  }
`;

const GENERATE_GIGS = gql`
  mutation GenerateGigs($eventId: ID!) {
    generateGigs(eventId: $eventId)
  }
`;

const CALCULATE_EVENT_STATE = gql`
  mutation calculateEventState($eventId: ID!) {
    calculateEventState(eventId: $eventId)
  }
`;

export const CHANGE_EVENT_STATE = gql`
  mutation ChangeEventState(
    $eventId: ID!
    $newState: EventStatus!
    $data: JSON
  ) {
    changeEventState(eventId: $eventId, newState: $newState, data: $data)
  }
`;

export { GENERATE_GIGS, EVENT_DETAIL, EVENT_GIGS, CALCULATE_EVENT_STATE };
