import { Mutation } from "@apollo/client/react/components";
import { Button, Card, Elevation, H2, InputGroup } from "@blueprintjs/core";
import React, { useState } from "react";
import { onLoggedIn } from "../utils/auth";
import { LOGIN } from "./gql";

const Login = ({ fetchUser }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVariables({ ...variables, [name]: value });
  };
  const [variables, setVariables] = useState({});
  return (
    <div className="container flex bp3-dark items-center h-full">
      <Mutation
        mutation={LOGIN}
        variables={variables}
        onError={window.alert}
        onCompleted={async ({ signIn: { token } }) => {
          onLoggedIn(token);
          await fetchUser();
        }}
      >
        {(login, { loading }) => (
          <Card
            className="mx-auto my-auto text-center"
            elevation={Elevation.FOUR}
          >
            <H2>Login</H2>

            <div className="group py-8">
              <InputGroup
                large
                leftIcon="envelope"
                type="text"
                name="email"
                placeholder="Email"
                className="pb-4"
                onChange={handleInputChange}
              />
              <InputGroup
                large
                leftIcon="key"
                type="password"
                name="password"
                placeholder="Password"
                id="password"
                className="pb-4"
                onChange={handleInputChange}
              />
            </div>
            <Button
              loading={loading}
              rightIcon="arrow-right"
              large
              onClick={(_) => {
                login();
              }}
            >
              Login
            </Button>
          </Card>
        )}
      </Mutation>
    </div>
  );
};

Login.propTypes = {};

export default Login;
