import { useQuery } from "@apollo/client";
import { H1 } from "@blueprintjs/core";
import React from "react";
import LoadingTable from "../Components/Table/LoadingTable";
import Pagination from "../Components/Table/Pagination";
import usePagination from "../Components/Table/usePagination";
import { SOUND_FEED } from "./gql";
import Sound from "./Sound";
const LIMIT = 10;

const SoundFeed = ({ history, isDj }) => {
  const { page, setPage } = usePagination({ id: "sounds" });

  const { data, loading } = useQuery(SOUND_FEED, {
    variables: {
      pagination: {
        page,
        limit: LIMIT,
      },
    },
  });

  if (loading) {
    return <LoadingTable columns={6} rows={LIMIT} className="mx-auto w-full" />;
  }

  const { pageInfo, edges } = data.soundFeed;

  console.log({ data });

  return (
    <div className="flex flex-col w-full p-6">
      <div className="flex flex-row justify-between mb-6">
        <div className="flex flex-row items-end">
          <H1 className="mb-0 mr-4">Sound Feed</H1>
        </div>
      </div>

      {edges.map((sound) => (
        <Sound key={sound.id} isOwn={false} {...sound} />
      ))}

      <Pagination {...pageInfo} setPage={setPage} />
    </div>
  );
};

export default SoundFeed;
