import React from "react";
import { Text } from "@blueprintjs/core";

const InfoStat = ({ unit, val, loading, className, ...props }) => (
	<div className={"flex flex-col " + className} {...props}>
		<Text
			className={
				"text-gray-400 tracking-wide font-semibold " +
				(loading ? "bp3-skeleton" : "")
			}
		>
			{val}
		</Text>
		<Text
			className={
				"text-gray-600 tracking-wide font-semibold" +
				(loading ? "bp3-skeleton" : "")
			}
		>
			{unit}
		</Text>
	</div>
);

export default InfoStat;
