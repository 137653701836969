import { Query } from "@apollo/client/react/components";
import { H1, H6, HTMLTable, Text } from "@blueprintjs/core";
import moment from "moment";
import React from "react";
import LoadingTable from "../Components/Table/LoadingTable";
import Pagination from "../Components/Table/Pagination";
import Td from "../Components/Table/Td";
import usePagination from "../Components/Table/usePagination";
import { CHATS } from "./gql";

const LIMIT = 12;

const Chats = ({ history }) => {
  const { page, setPage } = usePagination({ id: "events" });

  const navigate = (route) => history.push(route);

  return (
    <div className="flex flex-col w-full p-6">
      <div className="flex flex-row justify-between  mb-6">
        <div className="flex flex-row items-end">
          <H1 className="mb-0 mr-4">CHATS</H1>
        </div>
      </div>

      <ChatsTable page={page} setPage={setPage} navigate={navigate} />
    </div>
  );
};

const ChatsTable = ({ page, setPage, navigate }) => {
  const pagination = {
    page,
    limit: LIMIT,
  };

  return (
    <Query
      query={CHATS}
      variables={{
        pagination,
      }}
    >
      {({ loading, data = {} }) => {
        if (loading) {
          return (
            <LoadingTable columns={6} rows={LIMIT} className="mx-auto w-full" />
          );
        }
        const { chats = {} } = data || {};
        const { edges = [], pageInfo } = chats;

        return (
          <>
            <HTMLTable interactive className="mx-auto w-full">
              <tbody>
                {edges.map((u) => (
                  <ChatRow key={u.id} {...u} navigate={navigate} />
                ))}
              </tbody>
            </HTMLTable>
            <Pagination {...pageInfo} setPage={setPage} />
          </>
        );
      }}
    </Query>
  );
};

const ChatRow = ({ id, count, lastMessageDate, gig, navigate }) => {
  return (
    <tr key={id} onClick={(_) => navigate("/gig/" + id)}>
      <Td style={{ width: "14rem" }}>
        <H6 style={{ marginBottom: 0, lineHeight: "1.5em" }}>{`${
          gig ? gig.event.name : "no name"
        }`}</H6>
      </Td>
      <Td>
        <Text>{`${count} messages`}</Text>
      </Td>
      <Td>
        <Text>{`Last message: ${moment(lastMessageDate).format("lll")}`}</Text>
      </Td>
    </tr>
  );
};

export default Chats;
