import io from "socket.io-client";

export default class ChatService {
	constructor(chatId, token, senderId) {
		console.log(
			"connecting to: ",
			process.env.REACT_APP_CHAT_DOMAIN + "?room=" + chatId
		);
		this.domain = process.env.REACT_APP_CHAT_DOMAIN;
		this.chatId = chatId;
		this.token = token;
		this.senderId = senderId;
	}

	init({ showPersonalInformation }) {
		return new Promise((resolve, reject) => {
			const url =
				process.env.REACT_APP_CHAT_DOMAIN +
				"?room=" +
				this.chatId +
				"&token=" +
				this.token +
				(showPersonalInformation ? "&showPersonalInformation=true" : "");

			this.socket = io(url, {});

			this.socket.on("initialize chat", data => {
				resolve(data);
			});
			this.socket.on("new message", message => {
				!!this.onNewMessage && this.onNewMessage(message);
			});
		});
	}

	dispose() {
		return this.socket.close();
	}

	startedTyping = () => {
		this.socket.emit("started typing");
	};
	stoppedTyping = () => {
		this.socket.emit("stopped typing");
	};
}
