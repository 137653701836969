import gql from "graphql-tag";

const ALL_USERS = gql`
  query Users(
    $pagination: Pagination!
    $search: String
    $location: Area
    $isDj: Boolean
    $isOnStandby: Boolean
    $profileStatus: EditStatus
  ) {
    users(
      pagination: $pagination
      filter: { search: $search, location: $location, isDj: $isDj , isOnStandby: $isOnStandby, profileStatus: $profileStatus}
    ) {
      pageInfo {
        page
        prevPage
        nextPage
        totalPages
        totalDocs
      }
      edges {
        id
        email
        permalink
        picture {
          id
          path
        }
        playingLocation {
          name
          radius
          longitude
          latitude
        }
        appMetadata {
          roles
          emailVerified
          experience
          createdAt
          profileStatus
        }
        userMetadata {
          firstName
          lastName
        }
        userSettings{
          id
          standby
        }
        payoutMethods{
          id
          payoutType
          paymentProvider
        }
      }
    }
  }
`;

export { ALL_USERS };
