import React from "react";
import styled from "styled-components";
import { H6, Colors, H2 } from "@blueprintjs/core";
import { useSpring, animated } from "react-spring";


export const MetricGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px;
`;


const Wrapper = styled.div`
  background: ${Colors.DARK_GRAY4};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 140px;
  padding: 30px;
  padding-bottom: 24px;
  text-align: center;
  width: 150px;
  min-width: 22%;
  margin: 6px;
`;

const MetricValue = ({ metric, label, loading, isPercentage }) => {
  return (
    <Wrapper className={loading ? "bp3-skeleton" : ""}>
      <H6 style={{ color: Colors.GRAY3 }}>{label}</H6>
      {metric && <InnerValues metric={metric} isPercentage={isPercentage} />}
    </Wrapper>
  );
};

const InnerValues = ({ metric, isPercentage }) => {
  const { difference, percentage, value } = metric;
  const animatedValue = useSpring({ number: value, from: { number: 0 } });

  const positive = Math.sign(difference || percentage) === 1;

  return (
    <>
      <H2>
        <animated.span>
          {animatedValue.number.interpolate(n => n.toFixed(0))}
        </animated.span>
        {isPercentage && "%"}
      </H2>
      {difference && (
        <H6 style={{ color: positive ? Colors.GREEN5 : Colors.RED5 }}>
          {positive && "+"}
          {difference} {isPercentage && "pp."}
        </H6>
      )}
      {percentage && (
        <H6 style={{ color: positive ? Colors.GREEN5 : Colors.RED5 }}>
          {positive && "+"}
          {percentage}%
        </H6>
      )}
    </>
  );
};

export default MetricValue;
