import React from "react";
import { Text } from "@blueprintjs/core";

const InfoText = ({ children, loading, className }) => {
	const loadingClass = loading ? "bp3-skeleton" : "";

	return (
		<Text className={className + " text-gray-500 my-2 " + loadingClass}>
			<Text>{children}</Text>
		</Text>
	);
};

export default InfoText;
