import { Query } from "@apollo/client/react/components";
import {
  Button,
  H1,
  H5,
  Intent,
  MenuItem,
  Spinner,
  Tag,
  Text,
} from "@blueprintjs/core";
import moment from "moment";
import React from "react";
import {
  CALCULATE_EVENT_STATE,
  CHANGE_EVENT_STATE,
  EVENT_DETAIL,
  GENERATE_GIGS,
} from "./gql";

import { useMutation } from "@apollo/client";
import { Select } from "@blueprintjs/select";
import Genre from "../Components/Genres";
import InfoStat from "../Components/InfoStat";
import InfoText from "../Components/InfoText";
import Map from "../Components/Map";
import { statusIntentMap } from "../Events";
import { useReOpenSpamEventMutation } from "../generated/graphql";
import Gigs from "./Gigs";

const EventDetail = ({ match }) => {
  const { id } = match.params;
  const [generateGigs, { loading: generating }] = useMutation(GENERATE_GIGS, {
    variables: {
      eventId: id,
    },
  });

  const [reOpen, { loading: reOpening }] = useReOpenSpamEventMutation({
    variables: {
      eventId: id,
    },
    refetchQueries: [{ query: EVENT_DETAIL, variables: { id } }],
    awaitRefetchQueries: true,
  });

  const [calculateState, { loading: calculating }] = useMutation(
    CALCULATE_EVENT_STATE,
    {
      variables: {
        eventId: id,
      },
    }
  );

  const [mutate, { loading: loadingNewState }] = useMutation(
    CHANGE_EVENT_STATE,
    {
      refetchQueries: [{ query: EVENT_DETAIL, variables: { id } }],
    }
  );

  const selectNewState = (newState) => {
    // first confirm,
    const confirmed = window.confirm("Change the event state to: " + newState);

    if (confirmed) {
      mutate({
        variables: {
          eventId: id,
          newState,
        },
      });
    }
  };

  return (
    <>
      <Query query={EVENT_DETAIL} variables={{ id }}>
        {({ loading, data }) => {
          const { eventAdmin = {} } = data || {};
          const {
            name,
            status,
            contactName,
            contactEmail,
            contactPhone,
            guestsCount,
            review,
            start = {},
            end = {},
            duration = {},
            location = {},
            hash,
            genres = [],
            createdAt = {},
            description,
            rider = {},
            flagged,
            flaggedReason,
          } = eventAdmin;

          const loadingClass = loading ? "bp3-skeleton" : "";

          return (
            <div className="flex flex-col w-full p-6">
              <div
                className="flex flex-row justify-between border-b mb-6 align-center"
                style={{
                  borderColor: "rgba(255, 255, 255, 0.15)",
                }}
              >
                <div className="flex items-end items-end ">
                  <H1 className={loadingClass}>{`${name}`}</H1>
                  {location && (
                    <H5
                      className={"ml-4 mb-3 " + loadingClass}
                      loading={loading}
                    >
                      {location.name}
                    </H5>
                  )}
                  <p
                    className={"ml-2 mb-3 text-gray-500 " + loadingClass}
                    loading={loading}
                  >
                    {start.formattedDate}
                  </p>
                </div>
              </div>

              <div className="flex-row flex justify-between ">
                <div className="flex flex-col flex-1 ">
                  <div
                    className="flex flex-row justify-between"
                    style={{
                      width: 400,
                    }}
                  >
                    <InfoStat
                      loading={loading}
                      unit={"START"}
                      val={start.formattedTime}
                    />

                    <InfoStat
                      loading={loading}
                      unit={"END"}
                      val={end.formattedTime}
                    />

                    <InfoStat
                      loading={loading}
                      unit={"DURATION"}
                      val={duration.humanized}
                    />
                    <InfoStat
                      loading={loading}
                      unit={"GUESTS"}
                      val={guestsCount}
                    />
                  </div>

                  <div
                    className={
                      "flex flex-row max-w-xs flex-wrap my-8 " + loadingClass
                    }
                  >
                    {genres.map((g) => Genre({ label: g }))}
                  </div>

                  <Text>{description}</Text>
                </div>

                <div className="flex-1 flex flex-col px-16">
                  <InfoText loading={loading}>{`Created ${moment(
                    createdAt.UTC
                  ).fromNow()}`}</InfoText>
                  <InfoText loading={loading}>{contactName}</InfoText>
                  {contactPhone ? (
                    <InfoText loading={loading}>
                      <a href={"tel:" + contactPhone}> {contactPhone}</a>
                    </InfoText>
                  ) : (
                    <InfoText loading={loading}>No Phone number</InfoText>
                  )}
                  <InfoText loading={loading}>
                    <a href={"maito:" + contactEmail}>{contactEmail}</a>
                  </InfoText>

                  <InfoText loading={loading}>{rider.formatted}</InfoText>
                </div>

                <div
                  className={loadingClass + " rounded-full"}
                  style={{
                    height: 300,
                    width: 300,
                    overflow: "hidden",
                  }}
                >
                  {!loading && (
                    <Map
                      defaultZoom={9}
                      defaultCenter={{
                        lat: location.latitude,
                        lng: location.longitude,
                      }}
                      circles={[]}
                    />
                  )}
                </div>
              </div>

              <div className="flex flex-row mt-6 border-b border-t py-4 border-gray-600 bg-gray-700">
                <div>
                  <Tag
                    large
                    minimal
                    round
                    intent={statusIntentMap[status]}
                    className="text-center mr-2"
                  >
                    {status}
                  </Tag>
                  <Tag
                    large
                    minimal
                    round
                    intent={review ? Intent.SUCCESS : Intent.WARNING}
                    className="text-center mr-2"
                  >
                    {review ? "Is reviewed" : "Not reviewed"}
                  </Tag>
                  {!!flagged && (
                    <Tag
                      large
                      minimal
                      round
                      intent={Intent.DANGER}
                      className="text-center mr-2"
                    >
                      Flagged as Spam
                    </Tag>
                  )}
                  {flaggedReason && (
                    <Tag
                      large
                      minimal
                      round
                      intent={Intent.DANGER}
                      className="text-center mr-2"
                    >
                      {flaggedReason}
                    </Tag>
                  )}
                </div>
                <div style={{ flex: 1 }}></div>
                <div
                  className={"flex gap-2  flex-row flex-wrap   "}
                  style={{ width: "50%" }}
                >
                  {loadingNewState ? (
                    <span>
                      <Spinner
                        size={Spinner.SIZE_SMALL}
                        className="bp3-dark mr-6"
                      />
                    </span>
                  ) : (
                    <Select
                      filterable={false}
                      items={[
                        "INITIAL",
                        "OFFERING",
                        "ACCEPTED",
                        "CANCELLED",
                        "FINISHED",
                      ]}
                      itemRenderer={(s, { handleClick }) => {
                        return <MenuItem text={s} onClick={handleClick} />;
                      }}
                      noResults={<MenuItem disabled={true} />}
                      onItemSelect={selectNewState}
                    >
                      {/* children become the popover target; render value here */}
                      <Button text={status} rightIcon="double-caret-vertical" />
                    </Select>
                  )}

                  {flagged && (
                    <div>
                      <Button
                        disabled={reOpening}
                        rightIcon="add"
                        onClick={reOpen}
                      >
                        {reOpening ? (
                          <Spinner size={15}></Spinner>
                        ) : (
                          "Reopen spam event"
                        )}
                      </Button>
                    </div>
                  )}

                  <div>
                    <Button
                      disabled={calculating}
                      rightIcon="add"
                      onClick={calculateState}
                    >
                      {calculating ? (
                        <Spinner size={15}></Spinner>
                      ) : (
                        "Recalculate state"
                      )}
                    </Button>
                  </div>
                  <div>
                    <Button
                      disabled={generating}
                      rightIcon="add"
                      onClick={generateGigs}
                    >
                      {generating ? (
                        <Spinner size={15}></Spinner>
                      ) : (
                        "Generate Gigs"
                      )}
                    </Button>
                  </div>

                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://cueup.io/event/${id}/${hash}/overview`}
                  >
                    <Button rightIcon="arrow-right">View on website</Button>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://dashboard.stripe.com/search?query=${id}`}
                  >
                    <Button rightIcon="arrow-right">
                      View related payments
                    </Button>
                  </a>
                </div>
              </div>
              <div className="mt-6">
                <Gigs id={id}></Gigs>
              </div>
            </div>
          );
        }}
      </Query>
    </>
  );
};

export default EventDetail;
