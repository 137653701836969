import { Query } from "@apollo/client/react/components";
import { H2, HTMLTable } from "@blueprintjs/core";
import React, { useState } from "react";
import GigRow from "../Components/Table/GigRow";
import LoadingTable from "../Components/Table/LoadingTable";
import Pagination from "../Components/Table/Pagination";

const LIMIT = 25;

const GigsTable = ({ id, query, extractor }) => {
  const [page, setPage] = useState(1);

  return (
    <Query
      query={query}
      variables={{ id, pagination: { page, limit: LIMIT } }}
      onError={window.alert}
    >
      {({ loading, data = {} }) => {
        const { edges = [], pageInfo = {} } = extractor(data);

        if (loading) {
          return (
            <LoadingTable columns={6} rows={LIMIT} className="mx-auto w-full" />
          );
        }
        return (
          <div className="flex-col flex">
            <H2 className="text-left">{pageInfo.totalDocs} Gigs</H2>
            <HTMLTable interactive className="mx-auto w-full">
              <tbody>{edges.map((g) => GigRow(g))}</tbody>
            </HTMLTable>
            <Pagination {...pageInfo} setPage={setPage} />
          </div>
        );
      }}
    </Query>
  );
};

export default GigsTable;
