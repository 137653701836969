import { useApolloClient, useQuery } from "@apollo/client";
import {
  Card,
  Colors,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Overlay,
  Toaster,
} from "@blueprintjs/core";
import { ELEVATION_4 } from "@blueprintjs/core/lib/esm/common/classes";
import geocoder from "google-geocoder";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import styled from "styled-components";
import { reset } from "../ApolloProvider";
import { ReactComponent as Logo } from "../assets/logo.svg";
import Chats from "../Chats";
import LocationSelector from "../Components/LocationSelector";
import EventDetail from "../EventDetail";
import Events from "../Events";
import GigDetail from "../GigDetail";
import { SEARCH_LOCATION } from "../gql";
import Push from "../Push";
import SoundFeed from "../Sounds";
import BottomPlayer from "../Sounds/BottomPlayer";
import UserDetail from "../UserDetail";
import { DJs, Organizers } from "../Users";
import Overview from "./Overview";

const geo = geocoder({
  key: "AIzaSyDYsMT5dhTnBLMcAetq4NGVWUyijkrVSHs",
});

const getLocationName = ({ latitude, longitude }) => {
  return new Promise((resolve, reject) => {
    geo.reverseFind(latitude, longitude, (err, res) => {
      try {
        if (err) {
          return reject(err);
        }
        const { country, administrative_area_level_1, locality, city } = res[0];
        if (administrative_area_level_1) {
          return resolve(administrative_area_level_1.short_name);
        }
        if (locality) {
          return resolve(locality.short_name);
        }
        if (city) {
          return resolve(city.short_name);
        }
        if (country) {
          return resolve(country.long_name);
        }
      } catch (error) {
        reject(error);
      }
    });
  });
};

const Home = ({ history, location }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toaster = useRef();
  const { data } = useQuery(SEARCH_LOCATION);
  const { location: searchLocation } = data || {};

  const client = useApolloClient();

  const removeLocation = useCallback(() => {
    client.writeData({
      data: {
        location: null,
      },
    });
  }, [client]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const toast = toaster.current;

    const show = async () => {
      let name = `lat:${searchLocation.latitude.toFixed(
        2
      )} lng:${searchLocation.longitude.toFixed(2)}`;

      try {
        name = await getLocationName(searchLocation);
      } catch (error) {}

      toast.clear();
      toast.show({
        message: `Showing data in: ${name}`,
        intent: Intent.NONE,
        timeout: 0,
        action: {
          text: "Show all",
          onClick: removeLocation,
        },
      });
    };

    if (searchLocation) {
      show();
    }
  }, [removeLocation, searchLocation]);

  return (
    <>
      <div
        className={
          "bp3-dark flex flex w-full main-content" +
          (menuOpen ? " menu-open " : "")
        }
      >
        <BottomPlayer />

        <Toaster className="bp3-dark" ref={toaster} position="bottom" />

        <div className={"side-menu "}>
          <div
            className="fixed h-full flex justify-between flex-col "
            style={{ background: Colors.DARK_GRAY4 }}
          >
            <SideMenu history={history} location={location} />
          </div>
          <Menu />
        </div>
        <div className="overflow-scroll flex w-full">
          <div className="mx-50 container">
            <Route exact path="/" component={Overview} />

            <Route path="/organizers" component={Organizers} />
            <Route path="/djs" component={DJs} />
            <Route path="/user/:id" component={UserDetail} />

            <Route path="/events" component={Events} />
            <Route path="/event/:id" component={EventDetail} />

            <Route path="/gig/:id" component={GigDetail} />
            <Route path="/sounds" component={SoundFeed} />
            <Route path="/chats" component={Chats} />
            <Route path="/push" component={Push} />
          </div>
        </div>
      </div>
      <BurgerButton onClick={toggleMenu} />
    </>
  );
};

const SideMenu = ({ history, location }) => {
  const [showingMap, setShowingMap] = useState(false);

  return (
    <>
      <Overlay isOpen={showingMap} onClose={(_) => setShowingMap(false)}>
        <Card
          elevation={ELEVATION_4}
          style={{
            maxWidth: 900,
            maxHeight: 600,
            width: "100%",
            height: "100%",
            padding: 0,
            overflow: "hidden",
            alignSelf: "center",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <LocationSelector />
        </Card>
      </Overlay>
      <Menu large className={"bp3-dark"}>
        <Logo className="ml-2 mb-4 mt-2" />
        <MenuDivider />
        <MenuItem
          icon="home"
          text="Overview"
          active={location.pathname === "/"}
          onClick={() => history.push("/")}
        />
        <MenuItem
          icon="user"
          text="DJs"
          active={location.pathname === "/djs"}
          onClick={() => history.push("/djs")}
        />
        <MenuItem
          icon="user"
          text="Organizers"
          active={location.pathname === "/organizers"}
          onClick={() => history.push("/organizers")}
        />
        <MenuItem
          icon="calendar"
          text="Events"
          active={location.pathname === "/events"}
          onClick={() => history.push("/events")}
        />
        <MenuItem
          icon="chat"
          text="Chats"
          active={location.pathname === "/chats"}
          onClick={() => history.push("/chats")}
        />
        <MenuItem
          icon="notifications"
          text="Send push"
          active={location.pathname === "/push"}
          onClick={() => history.push("/push")}
        />
        {/* <MenuItem
          icon="music"
          text="Sound feed"
          active={location.pathname === "/sounds"}
          onClick={() => history.push("/sounds")}
        /> */}
      </Menu>
      <Menu large className={"bp3-dark"}>
        <MenuDivider />
        <MenuItem
          icon="map"
          text="Set Location"
          onClick={(_) => {
            setShowingMap(true);
          }}
        ></MenuItem>
        <MenuItem
          icon="log-out"
          text="Logout"
          onClick={(_) => {
            reset();
          }}
        />
      </Menu>
    </>
  );
};

const BurgerButton = styled.button`
  width: 50px;
  height: 50px;
  background: #1d272f;
  border-radius: 25px;
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 9999999;
  display: none;
  &:after {
    content: "⇾";
    font-size: 32px;
    top: -4px;
    position: relative;
    color: #fff;
  }
  @media (max-width: 640px) {
    display: block;
  }
`;

export default withRouter(Home);
