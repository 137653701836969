import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  H1,
  H6,
  Intent,
  Overlay,
  Spinner,
  Switch,
  Tag,
  Toaster,
} from "@blueprintjs/core";
import { ELEVATION_4 } from "@blueprintjs/core/lib/esm/common/classes";
import moment from "moment";
import React, { useRef, useState } from "react";
import CancelationPolicy from "../Components/CancelationPolicy";
import Genre from "../Components/Genres";
import InfoStat from "../Components/InfoStat";
import InfoText from "../Components/InfoText";
import Map from "../Components/Map";
import { EDIT_STATUS } from "../constants";
import { getAuthToken } from "../utils/auth";
import Gigs from "./Gigs";
import {
  BAN_USER,
  GENERATE_GIGS_FOR_USER,
  UPDATE_USER,
  USER_DETAIL,
} from "./gql";
import ReviewEdits from "./ReviewEdits";
import { useDeleteStripeConnectAccountMutation } from "../generated/graphql";

const UserDetail = ({ data, loading, id, refetch }) => {
  const { user = {} } = data || {};
  const {
    userMetadata = {},
    appMetadata = {},
    picture = {},
    playingLocation = {},
    userSettings = {},
    email,
    permalink,
    genres = [],
    payoutMethods = [],
    edits = [],
  } = user;
  const { firstName, lastName, phone, birthday, bio } = userMetadata;
  const { cancelationPolicy = {}, standby, currency } = userSettings;

  const {
    createdAt,
    experience,
    emailVerified,
    certified,
    identityVerified,
    hasInstalledApp,
    approved,
    profileStatus,
    internalRating,
    lastActiveAt,
    useLegacyPayout,
    stripeId,
    stripeCustomerId,
    banned,
  } = appMetadata;

  const [updateUser] = useMutation(UPDATE_USER);
  const [toggleBan] = useMutation(BAN_USER);
  const [showingMap, setShowingMap] = useState(false);
  const toaster = useRef();

  const [generatingGigs, setGeneratingGigs] = useState(false);
  const [generateGigs] = useMutation(GENERATE_GIGS_FOR_USER, {
    variables: {
      id,
    },
  });

  const [deleteStripeConnectMutation, { loading: deleteStripeConnectLoading }] =
    useDeleteStripeConnectAccountMutation({
      variables: {
        userId: id,
      },
    });

  const doGenerate = async () => {
    setGeneratingGigs(true);
    await generateGigs();
    setGeneratingGigs(false);
  };

  const doUpdate = (key) => async (val) => {
    console.log({ key, val });
    toaster.current.clear();
    toaster.current.show({
      message: "Saving",
      intent: Intent.NONE,
    });

    await updateUser({
      variables: {
        [key]: val,
        id,
      },
    });
    await refetch();

    toaster.current.clear();
    toaster.current.show({
      message: "Saved Succesfully",
      icon: "tick",
      intent: Intent.SUCCESS,
    });
  };

  const doShadowBan = async (val) => {
    toaster.current.clear();
    toaster.current.show({
      message: "Saving",
      intent: Intent.NONE,
    });

    await toggleBan({
      variables: {
        id,
        toggle: !!val,
      },
    });

    // refresh
    await refetch();

    toaster.current.clear();
    toaster.current.show({
      message: "Saved Succesfully",
      icon: "tick",
      intent: Intent.SUCCESS,
    });
  };

  return (
    <>
      <Toaster className="bp3-dark" ref={toaster} position="bottom" />

      <div className="flex flex-col w-full p-6">
        {playingLocation && (
          <Overlay isOpen={showingMap} onClose={(_) => setShowingMap(false)}>
            <Card
              elevation={ELEVATION_4}
              style={{
                maxWidth: 900,
                maxHeight: 600,
                width: "100%",
                height: "100%",
                padding: 0,
                overflow: "hidden",
                alignSelf: "center",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <Map
                defaultZoom={8}
                defaultCenter={{
                  lat: playingLocation.latitude,
                  lng: playingLocation.longitude,
                }}
                circles={[
                  {
                    center: {
                      lat: playingLocation.latitude,
                      lng: playingLocation.longitude,
                    },
                    radius: playingLocation.radius,
                  },
                ]}
              />
            </Card>
          </Overlay>
        )}
        <div
          className="flex flex-row justify-between border-b mb-6 align-center"
          style={{
            borderColor: "rgba(255, 255, 255, 0.15)",
          }}
        >
          <div className="flex items-end">
            <H1>{`${firstName} ${lastName}`}</H1>
          </div>
          <div className={"mt-2 "}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${
                process.env.REACT_APP_CUEUP_GQL_DOMAIN
              }/loginAsUser/${id}?token=${getAuthToken()}`}
            >
              <Button className="mr-4" rightIcon="arrow-right">
                Sign in as user
              </Button>
            </a>

            <Button
              disabled={generatingGigs}
              className="mr-4"
              rightIcon="add"
              onClick={doGenerate}
            >
              {generatingGigs ? <Spinner size={15}></Spinner> : "Generate Gigs"}
            </Button>

            {stripeId && (
              <Button
                disabled={deleteStripeConnectLoading}
                className="mr-4"
                onClick={() => {
                  const confirmed = window.confirm(
                    "Are you sure you want to delete this stripe account?"
                  );

                  if (confirmed) {
                    deleteStripeConnectMutation()
                      .then(() => {
                        refetch();
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                }}
              >
                {deleteStripeConnectLoading ? (
                  <Spinner size={15}></Spinner>
                ) : (
                  "Delete stripe account"
                )}
              </Button>
            )}

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://cueup.io/user/${permalink}`}
            >
              <Button rightIcon="arrow-right">View public profile</Button>
            </a>
          </div>
        </div>

        <div className="flex-row flex justify-between ">
          <div className="flex flex-col flex-1 ">
            <div
              className="flex flex-row justify-between"
              style={{
                width: 400,
              }}
            >
              <InfoStat loading={loading} unit={"GIGS"} val={experience} />

              <InfoStat
                loading={loading}
                unit={"PLAYED GIGS"}
                val={experience}
              />

              {playingLocation && (
                <InfoStat
                  onClick={(_) => setShowingMap(true)}
                  loading={loading}
                  unit={"LOCATION"}
                  val={playingLocation.name}
                  className={"cursor-pointer"}
                />
              )}
              <InfoStat loading={loading} unit={"CURRENCY"} val={currency} />
            </div>

            <div className={"flex flex-row max-w-xs flex-wrap my-8 "}>
              {genres.map((g) => Genre({ label: g }))}
            </div>

            {cancelationPolicy.days && (
              <CancelationPolicy
                cancelationPolicy={cancelationPolicy}
                loading={loading}
              />
            )}
          </div>

          <div
            className="flex-1 flex flex-col px-16"
            style={{ whiteSpace: "pre-line" }}
          >
            <H6>About {firstName}</H6>
            {bio}
          </div>

          <div className={" rounded-full "}>
            <img
              src={picture.path}
              alt="profile"
              className={" h-64 w-64 rounded-full "}
            />
          </div>
        </div>

        <div className="flex items-center flex-row mt-6 border-t py-4 border-gray-600">
          <div className="mr-4">
            <Switch
              large
              checked={certified}
              label="Cueup Certified"
              inline
              onChange={(e) => {
                doUpdate("certified")(!!e.target.checked);
              }}
            />
          </div>
          <div className="mr-4">
            <Switch
              large
              checked={standby}
              label="Standby"
              inline
              onChange={(e) => doUpdate("standby")(!!e.target.checked)}
            />
          </div>

          <div className="mr-4">
            <Switch
              large
              checked={approved}
              label="Approved / Active"
              inline
              onChange={(e) => doUpdate("approved")(!!e.target.checked)}
            />
          </div>

          <div className="mr-4">
            <Switch
              large
              checked={banned}
              label="Shadow Ban"
              inline
              onChange={(e) => doShadowBan(!!e.target.checked)}
            />
          </div>
          <div className="mr-4">
            <InfoText loading={loading}>{`Member for ${moment(
              createdAt
            ).fromNow(true)}`}</InfoText>
          </div>
          <div className="mr-4">
            <InfoText loading={loading}>{`Last active at ${moment(
              lastActiveAt
            ).fromNow(true)}`}</InfoText>
          </div>
          <div className="mr-4">
            {birthday && (
              <InfoText loading={loading}>
                {`${moment().diff(moment(birthday), "years")} years old`}
              </InfoText>
            )}
          </div>
          <div className="mr-4">
            {phone ? (
              <InfoText loading={loading}>
                <a href={"tel:" + phone}> {phone}</a>
              </InfoText>
            ) : (
              <InfoText loading={loading}>No Phone number</InfoText>
            )}
          </div>
          <div className="mr-4">
            <InfoText loading={loading}>
              <a href={"maito:" + email}>{email}</a>
            </InfoText>
          </div>
        </div>

        <div className="flex flex-row  border-b border-t py-4 border-gray-600">
          <Tag
            large
            minimal
            round
            intent={emailVerified ? Intent.SUCCESS : Intent.WARNING}
            className="text-center mr-2"
          >
            {emailVerified ? "Email verified" : "Email not verified"}
          </Tag>
          <Tag
            large
            minimal
            round
            intent={hasInstalledApp ? Intent.SUCCESS : Intent.WARNING}
            className="text-center mr-2"
          >
            {hasInstalledApp ? "App installed" : "Might not be using app"}
          </Tag>

          {payoutMethods.map((pm) => (
            <a
              key={pm.paymentProvider}
              target="_blank"
              rel="noreferrer noopener"
              href={"https://dashboard.stripe.com/connect/accounts/" + stripeId}
            >
              <Tag
                large
                minimal
                round
                intent={Intent.SUCCESS}
                className="text-center  mr-2"
              >
                Payout: {pm.paymentProvider}
              </Tag>
            </a>
          ))}

          <a
            target="_blank"
            rel="noreferrer noopener"
            href={
              stripeCustomerId
                ? "https://dashboard.stripe.com/customers/" + stripeCustomerId
                : null
            }
          >
            <Tag
              large
              minimal
              round
              intent={stripeCustomerId ? Intent.SUCCESS : Intent.NONE}
              className="text-center  mr-2"
            >
              Stripe customer
            </Tag>
          </a>
          {!!useLegacyPayout && (
            <Tag
              large
              minimal
              round
              intent={Intent.WARNING}
              className="text-center mr-2"
            >
              Stripe connect legacy
            </Tag>
          )}

          <Tag
            large
            minimal
            round
            intent={identityVerified ? Intent.SUCCESS : Intent.WARNING}
            className="text-center  mr-2"
          >
            {identityVerified
              ? "Identity is Verified"
              : "Identity not Verified"}
          </Tag>

          <Tag
            large
            minimal
            round
            intent={
              profileStatus === EDIT_STATUS.APPROVED
                ? Intent.SUCCESS
                : profileStatus === EDIT_STATUS.REJECTED
                ? Intent.DANGER
                : Intent.WARNING
            }
            className="text-center  mr-2"
          >
            {profileStatus}
          </Tag>
        </div>

        <div className="mt-6">
          <ReviewEdits
            edits={edits || []}
            userId={id}
            internalRating={internalRating}
          />
        </div>
        <div className="mt-6">
          <Gigs id={id}></Gigs>
        </div>
      </div>
    </>
  );
};

const DataWrapper = ({ match }) => {
  const { id } = match.params;
  const { data, loading, refetch } = useQuery(USER_DETAIL, {
    skip: !id,
    variables: { id },
  });

  if (!data || loading) {
    return null;
  }

  return <UserDetail data={data} loading={loading} id={id} refetch={refetch} />;
};

export default DataWrapper;
