import gql from "graphql-tag";

const GIG_DETAIL = gql`
  query GigDetail($id: ID!) {
    gig(id: $id) {
      id
      status
      statusHumanized
      directBooking
      seenByDJ
      seenByOrganizer
      chatInitiated
      reason
      affiliateLinkId
      offer {
        bookingConfirmedDate
        offer {
          formatted
        }
        serviceFee {
          formatted
        }
        djFee {
          formatted
        }
        totalPayment {
          formatted
        }
        totalPayout {
          formatted
        }
        cancelationPolicy {
          days
          percentage
        }
        canBePaid
        daysUntilPaymentPossible
      }
      event {
        id
        name
        status
        contactName
        contactEmail
        contactPhone
        start {
          UTC
          formattedDate
          formattedTime
        }
        end {
          UTC
          formattedDate
          formattedTime
        }
        createdAt {
          UTC
        }
        duration {
          formatted
        }
        location {
          name
          longitude
          latitude
        }
      }
      organizer {
        id
        email
        userMetadata {
          firstName
          lastName
        }
        picture {
          id
          path
        }
      }
      dj {
        id
        email
        userMetadata {
          firstName
          lastName
        }
        picture {
          id
          path
        }
      }
    }
  }
`;

const CHANGE_GIG_STATE = gql`
  mutation ChangeGigState($gigId: ID!, $newState: GigStatus!, $data: JSON) {
    changeState(gigId: $gigId, newState: $newState, data: $data)
  }
`;

export { GIG_DETAIL, CHANGE_GIG_STATE };
