import React from "react";

import { H6, Intent, Tag, Text } from "@blueprintjs/core";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { statusIntentMap } from "../../Events";
import Avatar from "../Avatar";
import Td from "./Td";

const GigRow = ({
  id,
  dj,
  event: { name, start },
  status,
  offer,
  statusHumanized,
  history,
  seenByDJ,
  seenByOrganizer,
  chatInitiated,
  reason,
  opportunity,
  ...rest
}) => {
  return (
    <tr key={id} onClick={(_) => history.push("/gig/" + id)}>
      {dj && (
        <Td>
          <div className="flex flex-row items-center">
            <Avatar path={dj.picture.path} />
            <div>
              <Text>{dj.userMetadata.firstName}</Text>
              <Text className="text-gray-500">{dj.email}</Text>
            </div>
          </div>
        </Td>
      )}
      <Td>
        <H6 style={{ marginBottom: 0 }}>{`${name}`}</H6>
      </Td>

      <Td>
        <Text>{`${moment(start.UTC).fromNow()}`}</Text>
      </Td>
      <Td>
        <Text>{`${offer ? offer.totalPayment.formatted : "No offer"}`}</Text>
      </Td>

      <Td>
        <Text>{`${statusHumanized}`}</Text>
      </Td>

      <Td>
        <Tag large minimal round intent={statusIntentMap[status]}>
          {status}
        </Tag>
      </Td>

      <Td>
        <Tag large minimal round intent={"none"}>
          {opportunity ? "Opportunity" : "Direct request"}
        </Tag>
      </Td>

      <Td>
        <Tag
          large
          minimal
          round
          intent={seenByDJ ? Intent.SUCCESS : Intent.WARNING}
        >
          {seenByDJ ? "Seen by dj" : "Not seen by dj"}
        </Tag>
      </Td>

      <Td>
        <Tag
          large
          minimal
          round
          intent={seenByOrganizer ? Intent.SUCCESS : Intent.WARNING}
        >
          {seenByOrganizer ? "Seen by organizer" : "Not seen by organizer"}
        </Tag>
      </Td>

      <Td>
        <Tag
          large
          minimal
          round
          intent={chatInitiated ? Intent.SUCCESS : Intent.WARNING}
        >
          {chatInitiated ? "Chat started" : "No messages"}
        </Tag>
      </Td>
    </tr>
  );
};

export default withRouter(GigRow);
