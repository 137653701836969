import gql from "graphql-tag";

const SOUND_FEED = gql`
  query SoundFeed(
    $pagination: Pagination!
  ) {
    soundFeed(
      pagination: $pagination
    ) {
      pageInfo {
        page
        prevPage
        nextPage
        totalPages
        totalDocs
      }
      edges {
                id
                createdAt
                title
                description
                samples
                tags
                source
                url
                duration {
                    totalSeconds
                }
                file {
                    id
                    path
                }
                image {
                    id
                    path
                }
                user{
                  id
                  displayName
                }
            }
            pageInfo {
                page
                nextPage
                hasNextPage
                totalDocs
                prevPage
                nextPage
                totalPages
            }
    }
  }
`;

export { SOUND_FEED };
