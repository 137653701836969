import React from 'react';

const SvgPauseIcon = (props) => (
    <svg width={16} height={19} {...props}>
        <g strokeWidth={5} fill="none" fillRule="evenodd" strokeLinecap="round">
            <path d="M13 2.912v13.176M3 2.912v13.176" />
        </g>
    </svg>
);

export default SvgPauseIcon;
