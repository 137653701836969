import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  Card,
  Elevation,
  H2,
  HTMLTable,
  Intent,
  Overlay,
  Tag,
  Text,
  TextArea,
} from "@blueprintjs/core";
import { ELEVATION_4 } from "@blueprintjs/core/lib/esm/common/classes";
import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import Avatar from "../Components/Avatar";
import Td from "../Components/Table/Td";
import { EDIT_STATUS } from "../constants";
import { GET_MEDIA, REVIEW_EDITS } from "./gql";

const ReviewEdits = ({ userId, edits, internalRating }) => {
  const [reviews, setReviews] = useState(edits);
  const [inReview, setInReview] = useState();
  const [rating, setRating] = useState(internalRating);

  const [mutate, { loading }] = useMutation(REVIEW_EDITS, {
    onError: window.alert,
  });

  const submitSingle = (review) => {
    if (!review.id) {
      setReviews((rr) => [...rr, review]);
    } else {
      setReviews((rr) => rr.map((r) => (r.id === review.id ? review : r)));
    }
    setInReview(false);
  };
  const submitReview = () => {
    const cleanReviews = reviews
      .filter((r) => r.edited)
      .map((review) => ({
        id: review.id,
        status: review.status,
        message: review.message,
      }));

    mutate({
      variables: {
        internalRating: rating,
        reviews: cleanReviews,
        userId,
      },
    });
  };

  const removeIdx = (idx) => {
    setReviews((rr) => rr.filter((r, j) => (idx === j ? false : true)));
  };

  const submitAllowed = reviews.every((r) => r.status !== EDIT_STATUS.PENDING);
  const approving =
    reviews.length && reviews.every((r) => r.status === EDIT_STATUS.APPROVED);
  const rejecting =
    reviews.length && reviews.some((r) => r.status === EDIT_STATUS.REJECTED);

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex">
          <H2 className="mr-6">Review Edits</H2>
          <ReactStars
            count={5}
            onChange={setRating}
            size={24}
            color2={"#ffd700"}
            value={rating}
            half={false}
          />
        </div>

        <ol className="list-decimal mb-4">
          <li>Require profile picture showing face and good quality</li>
          <li>Require a good bio if they haven't uploaded any other content</li>
          <li>Make sure to approve or reject all edits before finishing</li>
          <li>Give a rating using the stars</li>
        </ol>
      </div>
      <HTMLTable interactive className="mx-auto w-full">
        <tbody>
          {reviews.map((review, idx) => (
            <EditRow
              key={review.id}
              review={review}
              onClick={setInReview}
              remove={!review.id ? () => removeIdx(idx) : false}
            />
          ))}
          <EditRow review={{}} addNew onClick={setInReview} />
        </tbody>
      </HTMLTable>

      {!!reviews.length && (
        <div className="flex mt-4">
          <Button
            large
            intent={
              approving
                ? Intent.SUCCESS
                : rejecting
                ? Intent.DANGER
                : Intent.NONE
            }
            disabled={!submitAllowed}
            loading={loading}
            onClick={submitReview}
          >
            {approving
              ? "APPROVE PROFILE"
              : rejecting
              ? "REJECT PROFILE"
              : "FINISH REVIEW"}
          </Button>
        </div>
      )}
      <Overlay isOpen={!!inReview} onClose={(_) => setInReview(false)}>
        <Card
          elevation={ELEVATION_4}
          style={{
            maxWidth: 900,
            maxHeight: 600,
            width: "100%",
            height: "100%",
            padding: 24,
            overflow: "hidden",
            alignSelf: "center",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <ReviewForm
            review={inReview}
            onUpdate={setInReview}
            submit={submitSingle}
          />
        </Card>
      </Overlay>
    </div>
  );
};

const EditRow = ({ review, onClick, addNew, remove }) => {
  const { id, fieldName, status, value, message } = review;
  const isFile = fieldName === "profilePictureId";
  let fileId = isFile && value && value.match(/\d+/g)?.[0];
  const { data } = useQuery(GET_MEDIA, {
    variables: {
      id: fileId,
    },
    skip: !fileId,
  });

  return (
    <tr key={id} onClick={(_) => onClick(review)}>
      <Td>
        <Text>{addNew ? <b>Add message</b> : fieldName}</Text>
      </Td>
      <Td>
        {isFile ? <Avatar path={data?.getMedia?.path} /> : <Text>{value}</Text>}
      </Td>
      <Td>
        <Text>{message}</Text>
      </Td>
      <Td>
        {!addNew && (
          <Tag
            large
            minimal
            round
            intent={
              status === EDIT_STATUS.APPROVED
                ? Intent.SUCCESS
                : status === EDIT_STATUS.PENDING
                ? Intent.WARNING
                : Intent.DANGER
            }
          >
            {status}
          </Tag>
        )}
      </Td>

      <Td>{remove && <Button onClick={remove}>Remove</Button>}</Td>
    </tr>
  );
};

const reasons = {
  bio: [
    "Instagram handles aren't allowed in about you. To display Instagram content, connect it in the photos section.",
    "For security, phone numbers aren't allowed in profiles.",
    "For security, websites and other contact information aren't allowed in profiles.",
    "Email addresses and other contact information aren't allowed in about you. Always communicate directly through Cueup until a booking is confirmed.",
    "Please add to your bio by explaining what kind of events you play at and your accomplishments as a DJ.",
  ],
  profilePictureId: [
    "Profile picture should be a photo of you. If you want to display something else, you can upload it in the photos section.",
    "Please upload a higher quality profile photo including your face. Preferably one of you performing.",
  ],
  other: ["You need to upload a profile picture."],
};

const ReviewForm = ({ review, onUpdate, submit }) => {
  const { fieldName, message, value } = review;
  const isFile = fieldName === "profilePictureId";
  let fileId = isFile && value && value.match(/\d+/g)?.[0];
  const { data } = useQuery(GET_MEDIA, {
    variables: {
      id: fileId,
    },
    skip: !isFile,
  });

  const handleChange = (key) => (value) => {
    onUpdate((r) => ({ ...r, [key]: value }));
  };

  const onSubmit = (status) => {
    submit({ ...review, status, edited: true });
  };

  return (
    <div className="flex flex-col h-full overflow-scroll">
      <H2>{fieldName}</H2>
      {isFile ? (
        <Avatar
          path={data?.getMedia?.path}
          style={{ height: 200, width: 200 }}
        />
      ) : (
        <Text>{value}</Text>
      )}

      <div className="row mt-4  w-full">
        {(
          reasons[fieldName] ||
          Object.values(reasons).reduce((acc, r) => [...acc, ...r], [])
        ).map((r, idx) => (
          <Card
            onClick={() => handleChange("message")(r)}
            className="mb-2"
            key={idx}
            interactive={true}
            elevation={Elevation.TWO}
          >
            {" "}
            <p>{r}</p>
          </Card>
        ))}
      </div>
      <div className="row mt-4  w-full">
        <TextArea
          growVertically={true}
          large={true}
          className="flex-1 w-full"
          intent={Intent.PRIMARY}
          onChange={(e) => handleChange("message")(e.target.value)}
          defaultValue={message}
          value={message}
        />
      </div>
      <div className="flex-1" />
      <div className="row mt-auto  w-full">
        <ButtonGroup>
          <Button onClick={() => onSubmit(EDIT_STATUS.PENDING)} icon="time">
            Pending
          </Button>
          <Button onClick={() => onSubmit(EDIT_STATUS.REJECTED)} icon="error">
            Reject
          </Button>
          <Button onClick={() => onSubmit(EDIT_STATUS.APPROVED)} icon="tick">
            Approve
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ReviewEdits;
