import { Query } from "@apollo/client/react/components";
import { Spinner } from "@blueprintjs/core";
import React, { createContext, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import APIProvider from "./ApolloProvider";
import Home from "./Home";
import Login from "./Login";
import { ME } from "./Login/gql";

const defaultState = {
  approvalMode: false,
  setAppState: () => {},
};

export const AppContext = createContext(defaultState);

const App = () => {
  return (
    <Router>
      <Query query={ME}>
        {({ loading, data, refetch, error }) => {
          if (loading || !data) {
            return <Spinner className="self-center mx-auto bp3-dark"></Spinner>;
          }
          const { me } = data;

          if (!me || !me.appMetadata?.roles?.includes("ADMIN")) {
            return <Login fetchUser={refetch} />;
          }
          return <Home />;
        }}
      </Query>
    </Router>
  );
};

const Providers = () => {
  const [appState, setState] = useState(defaultState);
  const setAppState = (data) => setState((d) => ({ ...d, ...data }));

  return (
    <AppContext.Provider value={{ ...appState, setAppState }}>
      <APIProvider>
        <App />
      </APIProvider>
    </AppContext.Provider>
  );
};

export default Providers;
