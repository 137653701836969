import React, { useState } from "react";
import { H1 } from "@blueprintjs/core";
import Metrics from "./Metrics";
import DateRange from "../Components/DateRange";
import moment from "moment";

const Overview = () => {

  const today = moment();

  const [dateRange, setDateRange] = useState([ moment(today).subtract(30, 'days').toDate(), today.toDate()])
  const startDate = dateRange[0].toISOString();
  const endDate = dateRange[1].toISOString()

  return (
    <div className="flex flex-col w-full p-6">
      <div className="flex flex-row items-end flex-wrap">
        <H1>Overview</H1>
        <div style={{ flex: 1 }}></div>
        <DateRange value={dateRange} onChange={([d1, d2]) => {

            if(d1 > d2){
              setDateRange([d2, d1])
            }else{
              setDateRange([d1, d2])
            }
          
          }} />
      </div>
      <Metrics dateRange={dateRange} start={startDate} end={endDate} />
    </div>
  )
};

export default Overview;
