import React from "react";
import { Tag, Intent } from "@blueprintjs/core";

const Genre = ({ label }) => (
	<Tag
		key={label}
		className="mr-1 mb-1"
		minimal
		round
		large
		intent={Intent.PRIMARY}
	>
		{label}
	</Tag>
);

export default Genre;
