import React from "react";
import { ButtonGroup, Button, Text } from "@blueprintjs/core";

const Pagination = ({
	page,
	totalPages,
	totalDocs,
	nextPage,
	prevPage,
	setPage
}) => {
	if (totalPages === 1) {
		return null;
	}
	return (
		<div className="mx-auto mt-4">
			<ButtonGroup large className="mx-auto">
				{prevPage && (
					<Button icon="chevron-left" onClick={_ => setPage(prevPage)}></Button>
				)}
				{prevPage && (
					<Button onClick={_ => setPage(prevPage)}>{prevPage}</Button>
				)}
				<Button active>{page}</Button>
				{nextPage && (
					<Button onClick={_ => setPage(nextPage)}>{nextPage}</Button>
				)}
				{nextPage && (
					<Button
						icon="chevron-right"
						onClick={_ => setPage(nextPage)}
					></Button>
				)}{" "}
			</ButtonGroup>
			<Text className="text-center mt-2">{totalPages} pages</Text>
		</div>
	);
};

export default Pagination;
