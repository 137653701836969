import { useQuery } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import {
  Button,
  Checkbox,
  H1,
  H6,
  HTMLTable,
  Intent,
  Popover,
  Tag,
  Text,
} from "@blueprintjs/core";
import moment from "moment";
import React, { useState } from "react";
import LoadingTable from "../Components/Table/LoadingTable";
import Pagination from "../Components/Table/Pagination";
import Td from "../Components/Table/Td";
import usePagination from "../Components/Table/usePagination";
import { SEARCH_LOCATION } from "../gql";
import { useDebounce } from "../utils/useDebounce";
import { ALL_EVENTS } from "./gql";

const LIMIT = 12;

const Events = ({ history }) => {
  const { page, setPage } = usePagination({ id: "events" });

  const [orderByStartTime, setOrderByStartTime] = useState(false);

  const [search, setSearch] = useState();
  const [statusFilter, setstatusFilter] = useState([]);

  const debouncedSearch = useDebounce(search, 500);
  const navigate = (route) => history.push(route);

  const { data } = useQuery(SEARCH_LOCATION);
  const { location } = data || {};
  const cleanedLocation = location
    ? {
        latitude: location.latitude,
        longitude: location.longitude,
      }
    : null;

  return (
    <div className="flex flex-col w-full p-6">
      <div className="flex flex-row justify-between  mb-6">
        <div className="flex flex-row items-end">
          <H1 className="mb-0 mr-4">EVENTS</H1>
          {location && (
            <p className="mb-1">
              @
              <Tag minimal round>
                {Math.floor(location.latitude * 10000) / 10000}
              </Tag>
              <Tag minimal round>
                {Math.round(location.longitude * 10000) / 10000}
              </Tag>
            </p>
          )}
        </div>

        <div className="flex flex-row items-center">
          <Checkbox
            style={{ marginRight: 15, marginTop: 9 }}
            checked={orderByStartTime}
            label="Order by start time"
            onChange={(_) => setOrderByStartTime(!orderByStartTime)}
          />
          <Popover
            content={
              <Filter
                statusFilter={statusFilter}
                setstatusFilter={setstatusFilter}
              />
            }
            target={<Button text="Filter" />}
          />

          <div className="bp3-input-group bp3-large ml-4">
            <span className="bp3-icon bp3-icon-search"></span>
            <input
              className="bp3-input bp3-large"
              type="search"
              placeholder="Search"
              dir="auto"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>

      <EventsTable
        location={cleanedLocation}
        page={page}
        search={debouncedSearch}
        setPage={setPage}
        navigate={navigate}
        statusFilter={statusFilter}
        orderByStartTime={orderByStartTime}
      />
    </div>
  );
};

const EventsTable = ({
  page,
  search,
  location,
  setPage,
  navigate,
  statusFilter,
  orderByStartTime,
}) => {
  const filter = {};
  if (location && location.latitude) {
    filter.location = location;
  }
  if (search) {
    filter.search = search;
  }
  if (statusFilter && statusFilter.length > 0) {
    filter.eventStatus = statusFilter;
  }

  const pagination = {
    page,
    limit: LIMIT,
  };
  if (orderByStartTime) {
    pagination.orderBy = "START_TIME";
  }

  return (
    <Query
      query={ALL_EVENTS}
      variables={{
        pagination,
        ...filter,
      }}
    >
      {({ loading, data = {} }) => {
        if (loading) {
          return (
            <LoadingTable columns={6} rows={LIMIT} className="mx-auto w-full" />
          );
        }
        const { events = {} } = data || {};
        const { edges = [], pageInfo } = events;

        return (
          <>
            <HTMLTable interactive className="mx-auto w-full">
              <tbody>
                {edges.map((u) => (
                  <EventRow key={u.id} {...u} navigate={navigate} />
                ))}
              </tbody>
            </HTMLTable>
            <Pagination {...pageInfo} setPage={setPage} />
          </>
        );
      }}
    </Query>
  );
};

export const statusIntentMap = {
  ACCEPTED: Intent.PRIMARY,
  OFFERING: Intent.PRIMARY,
  CANCELLED: Intent.WARNING,
  CONFIRMED: Intent.SUCCESS,
  FINISHED: Intent.SUCCESS,
  NO_MATCHES: Intent.DANGER,
  REQUESTED: Intent.WARNING,
};

const EventRow = ({
  id,
  name,
  contactName,
  contactEmail,
  guestsCount,
  status,
  review,
  start,
  end,
  location,
  navigate,
}) => {
  return (
    <tr key={id} onClick={(_) => navigate("/event/" + id)}>
      <Td style={{ width: "14rem" }}>
        <H6 style={{ marginBottom: 0, lineHeight: "1.5em" }}>{`${name}`}</H6>
      </Td>
      <Td>
        <Text>{`${contactName}`}</Text>
      </Td>
      <Td>
        <Text>{`${contactEmail}`}</Text>
      </Td>
      <Td style={{ width: "8rem" }}>
        <Text>{`${location.name}`}</Text>
      </Td>
      <Td>
        <Text>
          {moment(start.UTC) < moment()
            ? `Ended ${moment(end.UTC).fromNow()}`
            : `Starts ${moment(start.UTC).fromNow()}`}
        </Text>
      </Td>
      <Td>
        <Text>{`${guestsCount} guests`}</Text>
      </Td>
      <Td>
        <Tag
          large
          minimal
          round
          intent={!!review ? Intent.SUCCESS : Intent.WARNING}
        >
          {!!review ? "Reviewed" : "Not reviewed"}
        </Tag>
      </Td>
      <Td>
        <Tag large minimal round intent={statusIntentMap[status]}>
          {status}
        </Tag>
      </Td>
    </tr>
  );
};

const Filter = ({ statusFilter, setstatusFilter }) => {
  const toggleStatus = (status) => (_) => {
    const exists = statusFilter.includes(status);
    if (exists) {
      setstatusFilter(statusFilter.filter((s) => s !== status));
    } else {
      setstatusFilter([...statusFilter, status]);
    }
  };

  return (
    <div className="p-4">
      <Checkbox
        checked={statusFilter.includes("INITIAL")}
        label="INITIAL"
        onChange={toggleStatus("INITIAL")}
      />
      <Checkbox
        checked={statusFilter.includes("ACCEPTED")}
        label="ACCEPTED"
        onChange={toggleStatus("ACCEPTED")}
      />
      <Checkbox
        checked={statusFilter.includes("OFFERING")}
        label="OFFERING"
        onChange={toggleStatus("OFFERING")}
      />
      <Checkbox
        checked={statusFilter.includes("CANCELLED")}
        label="CANCELLED"
        onChange={toggleStatus("CANCELLED")}
      />
      <Checkbox
        checked={statusFilter.includes("CONFIRMED")}
        label="CONFIRMED"
        onChange={toggleStatus("CONFIRMED")}
      />
      <Checkbox
        checked={statusFilter.includes("FINISHED")}
        label="FINISHED"
        onChange={toggleStatus("FINISHED")}
      />
      <Checkbox
        checked={statusFilter.includes("NO_MATCHES")}
        label="NO_MATCHES"
        onChange={toggleStatus("NO_MATCHES")}
      />
    </div>
  );
};

export default Events;
