import React from "react";
import { EVENT_GIGS } from "./gql";
import GigsTable from "../Components/GigsTable";

const Gigs = ({ id }) => {
	return (
		<GigsTable
			id={id}
			query={EVENT_GIGS}
			extractor={data => {
				const { eventAdmin = {} } = data;
				const { gigsPaginated = {} } = eventAdmin;
				return gigsPaginated;
			}}
		/>
	);
};

export default Gigs;
