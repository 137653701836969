import React from 'react';

const PlayIcon = (props) => (
    <svg
        width={13}
        height={15}
        style={{
            marginTop: '-2px',
            marginLeft: '3px',
        }}
        {...props}
    >
        <path
            d="M.91 8.035L.976 1.76A1 1 0 012.54.945l9.158 6.259a1 1 0 01-.015 1.661l-9.159 6.012a1 1 0 01-1.548-.825L.91 8.057a1 1 0 010-.022z"
            fillRule="evenodd"
        />
    </svg>
);

export default PlayIcon;
