import React, { useRef, useEffect } from "react";
import Chart from "chart.js";
import { Colors, H3 } from "@blueprintjs/core";
import moment from "moment";

Chart.defaults.global.defaultFontColor = Colors.GRAY5;

function LineChart({ data, label }) {
  const chart = useRef();

  useEffect(() => {
    const can = chart.current;
    const { values, lastPeriod,keys, startDate, startDateLastPeriod } = data || {};

    if(keys){
      createGrowthChart({
        ctx: can,
        values,
        keys
      })
    }else if (values) {
      createChart({
        ctx: can,
        startDate,
        startDateLastPeriod,
        values,
        lastPeriod
      });
    }
    return () => {
      const context = can.getContext("2d");
      context.clearRect(0, 0, can.width, can.height);
    };
  }, [data]);

  return (
    <div style={{ marginBottom: "30px", marginTop: "30px" }}>
      <H3 style={{ textAlign: "center" }}>{label}</H3>
      <canvas ref={chart} height={"400px"} />
    </div>
  );
}

const createChart = ({
  ctx,
  startDate,
  startDateLastPeriod,
  values,
  lastPeriod
}) => {
  return new Chart(ctx, {
    type: "line",
    height: 500,
    data: {
      datasets: [
        {
          borderColor: "#31fff5",
          backgroundColor: "#31fff530",
          label: "This period",
          data: values,
          borderWidth: 2
        },
        {
          label: "Previous period",
          data: lastPeriod,
          borderWidth: 2
        }
      ],
      labels: Array.from({ length: values.length }, (_, idx) =>
        moment(startDate)
          .add(idx, "days")
          .format("MMM DD")
      )
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        xAxis: [
          {
            id: "x1",
            type: "linear",
            position: "bottom"
          },
          {
            id: "x2",
            type: "linear",
            position: "bottom"
          }
        ]
      }
    }
  });
};


const createGrowthChart = ({
  ctx,
  values,
  keys
}) => {
  let count = 0;
  return new Chart(ctx, {
    type: "line",
    height: 500,
    data: {
      datasets: [
        {
          borderColor: "#31fff5",
          backgroundColor: "#31fff530",
          label: "This period",
          data: values.map(v => {count += v; return count;}),
          borderWidth: 2
        },
      ],
      labels: keys
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        xAxis: [
          {
            id: "x1",
            type: "linear",
            position: "bottom"
          },
        ]
      }
    }
  });
};

export default LineChart;
