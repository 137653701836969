import React from "react";
import { compose } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle
} from "react-google-maps";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)(props => {
  let {
    defaultCenter,
    defaultZoom,
    circles,
    children,
    heatmapData,
    ...rest
  } = props;
  defaultCenter = !!defaultCenter ? defaultCenter : { lat: 56.0, lng: 10.0 };
  defaultZoom = !!defaultZoom ? defaultZoom : 7;

  console.log({ heatmapData });

  return (
    <GoogleMap
      defaultZoom={defaultZoom}
      defaultCenter={defaultCenter}
      streetViewControl={false}
      defaultOptions={{
        scrollwheel: false,
        streetViewControl: false,
        mapTypeControl: false,
        styles: [
          {
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "water",
            stylers: [{ visibility: "simplified" }, { color: "#4a5567" }]
          },
          {
            featureType: "landscape",
            stylers: [{ visibility: "on" }, { color: "#32325D" }]
          },
          {
            featureType: "administrative.locality",
            elementType: "labels",
            stylers: [{ color: "#ffffff" }, { visibility: "on" }]
          },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#32325D" }, { visibility: "on" }]
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ visibility: "off" }]
          },
          {
            featureType: "administrative.province",
            stylers: [{ visibility: "on" }]
          },
          {
            featureType: "administrative.province",
            elementType: "labels.text.fill",
            stylers: [{ color: "#ffffff" }, { visibility: "on" }]
          },
          {
            featureType: "administrative.province",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#32325D" }, { visibility: "on" }]
          },
          {
            featureType: "administrative.country",
            stylers: [{ visibility: "on" }]
          },
          {
            featureType: "administrative.country",
            elementType: "labels.text.fill",
            stylers: [{ color: "#ffffff" }, { visibility: "on" }]
          },
          {
            featureType: "administrative.country",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#32325D" }, { visibility: "on" }]
          }
        ]
      }}
      {...rest}
      onPositionChanged={console.log}
    >
      {circles &&
        circles.map((c, idx) => {
          return (
            <Circle
              key={"dj-" + idx}
              defaultOptions={{
                fillColor: "#31daff",
                strokeWeight: 0,
                opacity: 0.01
              }}
              center={c.center}
              radius={c.radius}
            />
          );
        })}
      {heatmapData && (
        <HeatmapLayer
          data={heatmapData}
          defaultOptions={{
            dissipating: false
            // radius: 20
            // gradient: "#31fff5"
          }}
        />
      )}
      {children}
    </GoogleMap>
  );
});

const Map = props => {
  return (
    <MapWithAMarker
      {...props}
      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAQNiY4yM2E0h4SfSTw3khcr9KYS0BgVgQ&libraries=geometry,drawing,places,visualization"
      loadingElement={
        <div style={{ height: `100%`, backgroundColor: "blue" }} />
      }
      containerElement={
        <div
          style={{ height: `100%`, width: "100%", backgroundColor: "blue" }}
        />
      }
      mapElement={<div style={{ height: `100%`, backgroundColor: "blue" }} />}
    />
  );
};

export default Map;
