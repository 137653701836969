import { useApolloClient, useQuery } from "@apollo/client";
import { Button } from "@blueprintjs/core";
import React from "react";
import { Marker } from "react-google-maps";
import { SEARCH_LOCATION } from "../gql";
import Map from "./Map";

const LocationSelector = () => {
  const client = useApolloClient();

  const setLocation = (location) => {
    client.writeData({
      data: {
        location,
      },
    });
  };

  const onDragEnd = ({ latLng }) => {
    const longitude = latLng.lng();
    const latitude = latLng.lat();
    setLocation({ __typename: "Location", longitude, latitude });
  };

  const { data } = useQuery(SEARCH_LOCATION);
  const { location } = data || {};

  return (
    <div className="h-full w-full flex flex-col">
      <div className="flex flex-1">
        <Map defaultZoom={2} onClick={onDragEnd}>
          {location && (
            <Marker
              position={{ lat: location.latitude, lng: location.longitude }}
              defaultDraggable
              onDragEnd={onDragEnd}
            />
          )}
        </Map>
      </div>
      <div className="my-6 mx-auto">
        <Button onClick={(_) => setLocation(null)}>No location</Button>
      </div>
    </div>
  );
};

export default LocationSelector;
